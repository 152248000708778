import { AuthService } from './../../../../services/self/seguridad/auth.service';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { _ } from 'underscore'
import { ifError } from 'assert';
import { ScreenActionsService } from 'src/app/services/self/seguridad/actions.service';
import * as $ from 'jquery';
import { NotificacionService } from 'src/app/services/bi/operacion/notificaciones.service';
import { ConciliacionService } from 'src/app/services/bi/conciliaciones/conciliacion.service';
import * as moment from 'moment'
import { ExcelService } from 'src/app/services/bi/operacion/excel.service';
import { ShareComponent } from '../sharecomponent/share.component';


@Component({
  selector: 'app-companias',
  templateUrl: './companias.component.html',
  styleUrls: ['./companias.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss'],
})
export class CompaniasConciliacionesComponent implements OnInit {
  @ViewChild('sharecmp') sharecmp: ShareComponent;



  //--Acciones y Permisos ---
  public Page: any = {};
  public Screen: any = {};
  //Manejo de Pantalla
  ListaClientes: any = [];
  ListaCompanias: any = [];
  ListaMonedas: any = [];
  ListaDocumentos: any = [];
  Filter: any = {};
  RfcSeleccionado: any = {};
  //Otros 
  General: any = {};
  Auth: any = {};
  Task: any = {};
  //Facturacion
  ListaFacturacion: any = [];
  ListaDiferenciaFolios: any = [];
  public p: number;
  Periodo: any = {};
  //Paginacion
  itemsPerPage: any;
  ListaItemsPerPage: any = [];

  constructor(private router: Router, private toastr: CommontoastrService, private AuthService: AuthService,
    private spinnerService: Ng4LoadingSpinnerService, private ScreenActionsService: ScreenActionsService,
    private NotificacionService: NotificacionService, private ConciliacionService: ConciliacionService,
    private ExcelService: ExcelService) {
    this.Page.AllowView = false;
    this.Page.AllowAdd = false;
    this.Page.AllowEdit = false;
    this.Page.Controller = this.router.routerState.snapshot.url.toString().substr(this.router.routerState.snapshot.url.lastIndexOf('/') + 1, this.router.routerState.snapshot.url.toString().length - 1);
    this.Filter.ObjectId = "0";
    //this.AuthService.IsAlive();
    this.itemsPerPage = 10;
    this.ListaItemsPerPage.push({ Id: 10, value: "10 registros" }, { Id: 20, value: "20 registros" }, { Id: 50, value: "50 registros" })
    this.ListaMonedas.push({ Id: '1', value: "Todas" }, { Id: 'MXN', value: "MXN" }, { Id: 'USD', value: "USD" }, { Id: 'EUR', value: "EUR" })
    this.General.TipoError = 0;
    this.Filter.Moneda = "1";
    console.log('Companias component')

  }

  ngOnInit() {
    var data = localStorage.getItem('auth');
    if (data != undefined && data != null) {
      this.Auth = JSON.parse(data);
      this.General.IdUser = this.Auth.objectId;
    }
    this.spinnerService.show();

    //Validacion de Acceso a la Pagina 
    this.ScreenActionsService.GetScreenActions(this.Page.Controller).then(data => {
      this.Screen.View = data.Get === undefined ? false : data.Get;;
      this.Screen.ViewAdd = data.Post === undefined ? false : data.Post;;
      this.Screen.ViewEdit = data.Put === undefined ? false : data.Put;;
      this.Screen.ViewDelete = data.Delete === undefined ? false : data.Delete;
      console.log(this.Screen);
      this.Screen.Diferecias = false;

      if (!this.Screen.View) {
        this.toastr.Error("Acceso denegado, no tiene permisos para ver el contenido.");
        setTimeout(() => {
          this.router.navigate(['default'])
        }, 3000);
      }
      else {
        this.CargarClientes(this.Auth.email);
      }

    }).catch(error => {
      this.handleError(error);
    })

  }


  CargarClientes(Id: any) {
    // this.NotificacionService.GetClients(Id).subscribe(data => {
    //   this.ListaClientes = data
    //   this.spinnerService.hide();
    // },
    //   error => {
    //     this.handleError(error);
    //   });

    this.ConciliacionService.Get({}).subscribe(data => {
      this.ListaCompanias = data
      console.log(this.ListaCompanias);
      this.sharecmp.DataInput = this.ListaCompanias;
      this.sharecmp.Verifica();
      this.spinnerService.hide();

    },
      error => {
        this.handleError(error);
      });

  }

  SeleccionConciliacion(id) {

    this.General.TipoEmision = id;

    if (id == 1) {
      this.General.Title = "- Cfdis Recibidos ";
      this.General.SeleccionEmisor = "Seleccione RFC Receptor";
      this.General.SeleccionReceptor = "RFC Emisor";
    }
    else {
      this.General.Title = "- Cfdis Emitidos ";
      this.General.SeleccionEmisor = "Seleccione RFC Emisor";
      this.General.SeleccionReceptor = "RFC Receptor";
    }
    this.Screen.Tools = true;
  }

  CambiarConciliacion() {
    this.General.Title = "";
    this.Screen.Tools = false;
    this.Filter = {};
    this.Filter.ObjectId = "0";
  }

  BuscarComprobantes() {

    console.log(this.ConciliacionService.FromShareService);
    this.Filter.ObjectId = this.ConciliacionService.FromShareService;
    this.RfcSeleccionado = {};
    this.Filter.RFC = '';
    this.General.TipoError = 0;
    this.ListaDiferenciaFolios.length = 0;
    this.Screen.Diferecias = false;
    /*console.log(this.Filter);
    if (this.Filter.DateDocumentIni === undefined || this.Filter.DateDocumentIni === null) {
      this.toastr.Error("Debe seleccionar la fecha inicial");
      return;
    }

    if (this.Filter.DateDocumentEnd === undefined || this.Filter.DateDocumentEnd === null) {
      this.toastr.Error("Debe seleccionar la fecha final");
      return;
    }

    let FecInicial = moment(this.Filter.DateDocumentIni.toString());
    var FechaFinal = moment(this.Filter.DateDocumentEnd.toString());

    let DiferenciaDias = FechaFinal.diff(FecInicial, 'days')

    if (DiferenciaDias < 0) {
      this.toastr.Error("El periodo seleccionado es érroneo, favor de corregir las fechas");
      return;
    }

    if (DiferenciaDias > 7) {
      this.toastr.Error("Solo puede consultar un periodo máximo de 7 días");
      return;
    }*/



    if (this.Filter.ObjectId && this.Filter.ObjectId !== "0") {

      let RfcSeleccionado = _.where(this.ListaCompanias, { ObjectId: parseInt(this.Filter.ObjectId) });   //EmailDetail.List
      if (RfcSeleccionado.length > 0) {

        this.RfcSeleccionado.RazonSocial = RfcSeleccionado[0].Razon_Social;
        this.Filter.RFCEmisor = RfcSeleccionado[0].Rfc;

        this.spinnerService.show();
        console.log("ObteniendoFActuracions");
        this.ConciliacionService.GetFacturacionCompania(this.Filter).subscribe(data => {
          this.ListaFacturacion = data;
          //console.log(this.ListaFacturacion);
          this.spinnerService.hide();

        },
          error => {
            this.handleError(error);
          });

      }




    }
    else
      this.toastr.Error("Debe seleccionar la información requerida");



  }

  DetalleDiferecias(item) {
    this.General.TipoError = 0;
    this.General.IdDetalle = item.ObjectId;
    this.spinnerService.show();
    this.Periodo.Mes = item.Mes;
    this.Periodo.DiferenciaPeriodo = item.DiferenciaPeriodo;
    this.Screen.Diferecias = true;
    let data: any = {};
    data.ObjectId = this.General.IdDetalle

    this.ConciliacionService.GetDiferencias(data).subscribe(data => {
      this.ListaDiferenciaFolios = data;
      this.spinnerService.hide();

    },
      error => {
        this.handleError(error);
      });

  }


  FiltrarDetalle() {
    let data: any = {};

    if (this.General.IdDetalle) {
      data.ObjectId = this.General.IdDetalle

      if (this.General.TipoError == "0")
        data.TipoError = "";
      else
        data.TipoError = this.General.TipoError;


      data.Moneda = this.Filter.Moneda;


      this.ConciliacionService.GetDiferencias(data).subscribe(data => {
        this.ListaDiferenciaFolios = data;
        this.spinnerService.hide();

      },
        error => {
          this.handleError(error);
        });


    }



    this.ConciliacionService.GetDiferencias(data).subscribe(data => {
      this.ListaDiferenciaFolios = data;

      this.spinnerService.hide();


    },
      error => {
        this.handleError(error);
      });



  }


  DescargaDiferenciasPeriodo() {
    this.ExcelService.exportAsExcelFile(this.ListaDiferenciaFolios, 'Diferencias');
  }


  //---------Funciones Manejo de Errores--------------------------
  private handleError(error: any) {

    this.spinnerService.hide();
    if (error.status == 401) {
      localStorage.removeItem('auth');
      this.router.navigate(['login'])
    }
    this.toastr.Error(error.error.Descripcion);

  };


}
