import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { PagosService } from 'src/app/services/bi/pagos/pagos.service';
import { AuthService } from 'src/app/services/self/seguridad/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss'],
})
export class PrincipalComponent implements OnInit {

  @Output() padre = new EventEmitter();
  @Input() selectedDS : any = "";
  @Input() sEDI : any = 0;

  Auth: any = {};

  hdrCartera : any = []

  listaPagos : any = []
  selectedPago : any = {}

  listaECPM : any = []
  selectedECPM : any = 0;

  listaCPM : any = []
  selectedCPM : any = 0//167405;

  selectedClientType : any = 1;
  PDate1 : any = "";
  PDate2 : any = "";
  importemin : any = "";
  importemax : any = "";
  
  listaRG : any = []
  selectedRG : any = 0

  listaDT : any = []
  selectedDT : any = 0

  listaDSt : any = []
  selectedDSt : any = 0

  listaDSo : any = []
  selectedDSo : any = 0

  listaPP : any = []
  xlistaPP : any = []
  selectedPP : any = 0

  documento : any = 0

  //utilidad
  timefired : any  = null;
  valorECPM:any = false;
  actual:any = ""
  filtershow : any = true

  inputEl:any;
  constructor(private http: HttpClient, private toastr:CommontoastrService, private pservice:PagosService, private spinnerService: Ng4LoadingSpinnerService) { 
    var data = localStorage.getItem('auth');
    if (data != undefined && data != null) {
      this.Auth = JSON.parse(data);
    }
    
  }

  ngOnInit() {
    this.cargaInicial("start")
  }
  cargaInicial(start:any){
    if(start == "start"){
      this.selectedECPM = this.sEDI
    }
    console.log(this.selectedDS);
    
    this.spinnerService.show()

    this.pservice.ObtenerCombos({}).subscribe(data => {
      
      console.log(data);
      this.listaECPM = data['ecpm']
      this.listaDT = data['dt']
      this.listaDSt = data['dSt']
      this.listaDSo = data['dSo']
      console.log(this.selectedDS);
      if(this.selectedDS != ""){
        this.documento = parseInt(this.selectedDS)
        this.filtrar()
      }else{
        this.spinnerService.hide()
      }

    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        //this.handleError(error);
      });
  }

  test(e:any){
      clearTimeout(this.timefired);
      var x = this.spinnerService;
      var y = this.pservice;
      var z = this
      if(this.selectedECPM == NaN || this.selectedECPM == null)
        this.selectedECPM = 0
      var obj = {
        EDI:this.selectedECPM,
        RG:this.selectedRG,
        PP:parseInt(this.selectedPP),
        texto:e.term
      }
      this.timefired = setTimeout(function () {
        
        
        console.log(obj);
        x.show()
        y.ObtenerClienteCPM(obj).subscribe(data => {
          x.hide()
          console.log(data);
          z.listaCPM=data
          
         
          
          
        },
          error => {
            x.hide()
            console.log("Error");
            
            //this.handleError(error);
          });
      }, 2000);
        
    
  }

  


  selectECPM(){
    
    this.spinnerService.show()
    
    this.pservice.ObtenerCombosRGyPP(this.selectedECPM).subscribe(data => {
      this.spinnerService.hide()
      console.log(data);
      this.listaPP = data['pp']
      this.xlistaPP = this.listaPP
      this.listaRG = data['rg']
   
      
      
      
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        //this.handleError(error);
      });
  }

  changeRG(){
    if(this.selectedRG == 0){
      this.xlistaPP = this.listaPP
      return
    }
    this.xlistaPP = []
    this.listaPP.forEach(e => {
      if(e.referenceGroupObjectId == this.selectedRG)
      this.xlistaPP.push(e)
    });
  }

  changePP(){
    console.log(this.selectedPP);
    if(this.selectedPP == 0)
      return
    var PP = {}
    for (let i = 0; i < this.listaPP.length; i++) {
      if(this.listaPP[i].objectId == this.selectedPP)
        PP = this.listaPP[i]  
    }

    this.selectedRG = PP['referenceGroupObjectId']
    this.changeRG()
  }

  detalle(e:any){
    this.selectedPago = e
    console.log(this.selectedPago);
    this.actual = "detalle"
  }

  editar(e:any){
    this.actual="editar"
  }

  showpadre(e:any){
    this.actual = ""
  }

  filtrar(){
    if(this.selectedECPM == NaN || this.selectedECPM == null)
      this.selectedECPM = 0
    var body = {
      EDI:parseInt(this.selectedECPM),
      CPM:parseInt(this.selectedCPM),
      RG:parseInt(this.selectedRG),
      PP:parseInt(this.selectedPP),
      DT:parseInt(this.selectedDT),
      DSt:parseInt(this.selectedDSt),
      DSo:parseInt(this.selectedDSo),
      Doc:this.documento,
      PDate1:this.PDate1==""?'2000-01-01':this.PDate1,
      PDate2:this.PDate2==""?'2022-01-01':this.PDate1,
      importemin:this.importemin==""?0:parseFloat(this.importemin),
      importemax:this.importemax==""?90000000:parseFloat(this.importemax)

    }

    console.log(body);
    console.log("asd");
    this.spinnerService.show()
    
    this.pservice.ObtenerListaPaymentHDR(body).subscribe(data => {
      console.log(data[0]);
      this.spinnerService.hide()
      this.listaPagos = data[0]
      
      
      
      
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        //this.handleError(error);
      });
  }
  
  readFile(event) {
    this.inputEl = event.target.files;
    const fileCount: number = this.inputEl.length;
    const formData = new FormData();
    const headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.delete('Content-Type'); // mandate for accepting binary content
    if (fileCount > 0) {
        for (let i = 0; i < fileCount; i++) {
          
            formData.append('file', this.inputEl.item(i));
        }
        
        try {
     
            this.spinnerService.show()
            this.http
                .post('http://localhost:5012/api/payments/PaymentDTLFile/'+this.Auth.objectId, formData, { headers: {} })
                .subscribe(response => {
                  this.spinnerService.hide()
                  console.log("response");
                  console.log(response[0][0]);
                  if(response[0][0].exito){
                    this.toastr.Success(response[0][0].exito)
                    this.cargaInicial("")
                  }else{
                    this.toastr.Error(response[0][0].error);
                    
                  }
                  
                }, error => {
                  this.spinnerService.hide()
                    console.log("error2");
                    console.log(error);
                    this.toastr.Error('Error al cargar archivos');
                });
        } catch (e) {
            console.log('Error occured while posting uploaded file. See below message for details : \n');
            console.log(e);
        }
    }
  }

  changeExt (fileName, newExt) {
    var _tmp
    return fileName.substr(0, ~(_tmp = fileName.lastIndexOf('.')) ? _tmp : fileName.length) + '.' + newExt
  }
  volver(){
    this.padre.emit([]);
  }

  showFilters(e:boolean){
    console.log(e);
    this.filtershow = e
  }

}
