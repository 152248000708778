import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceService } from 'src/app/shared/resource.service';


@Injectable({
  providedIn: 'root'
})
export class ConciliacionService {
  public FromShareService: any= {};

  constructor(private api: ResourceService, private router: Router) {}

  //--------() Api gateway)--------------------//

  
  

  Get(data:any) {
    //return this.api.get('paymentservices/CompaniesConciliation', data);
    return this.api.get('CompaniesConciliation', data);
  }

  // GetById(data:any) {
  //   return this.api.getById2('CompaniesConciliation/Documents', data);
  // }

  //  GetDocuments(data:any) {
  //   return this.api.post('paymentservices/CompaniesConciliation/Invoice', data);
  // }

  GetFacturacionCompania(data:any) {
    //return this.api.post('paymentservices/CompaniesConciliation/Invoice', data);
    return this.api.post('CompaniesConciliation/Invoice', data);
  }

  GetDiferencias(data:any) {
    //return this.api.post('paymentservices/CompaniesConciliation/Differences', data);
    return this.api.post('CompaniesConciliation/Differences', data);
  }

  //--------Api a local host (sin api gateway)--------------------//

/*

  GetFacturacionCompania(data:any) {
    return this.api.post('CompaniesConciliation/Invoice', data);
  }
  
  
  GetDiferencias(data:any) {
    return this.api.postDev('CompaniesConciliation/Differences', data);
  }

*/
 

//--------------------------------------------------------------------//
 
/*
  GetById(data:any) {
    return this.api.getById('notificationservices/Notification', data);
     
  }
 */

}
