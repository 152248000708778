import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RfcsServiceService } from 'src/app/services/bi/comprobantes/rfcs-service.service';
import { CrudrfcService } from 'src/app/services/bi/comprobantes/crudrfc.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';

@Component({
  selector: 'app-rfcs',
  templateUrl: './rfcs.component.html',
  styleUrls: ['./rfcs.component.scss']
})
export class RfcsComponent implements OnInit {
  @Input() public data : any;
  @Output() voted = new EventEmitter<any>();
  public files: any;
  public filestring: any;
  public keyString: string = "";
  public cerString: string = "";
  public keyData: string = "";
  public cerData: string = "";
  public v_nc_estatus:any=false;
  public v_nc_razonsocial:any=false;
  public v_nc_comprobantes:any=false;
  public v_nc_PFX:any=false;


  texto_input_cer:string = "Seleccionar archivo: Certificado (.cer)"
  texto_input_key:string = "Seleccionar archivo: Certificado (.key)"

  constructor(private service:RfcsServiceService, private crudservice:CrudrfcService, 
    private spinnerService: Ng4LoadingSpinnerService, private toastr: CommontoastrService) {


  }

  ngOnInit() {

  }


  volver(){
    var obj = {
      show : false,
      mensaje:"Volviste"
    }
    this.voted.emit(obj);

  }

  updateRfc(data){
    
  }


  

  SaveChanges(){
    this.resetBools()
    if(this.data.razon_Social.length < 4){
      this.toastr.Error("Error en el formulario");
      this.v_nc_razonsocial = true;
    }else if(this.data.estatus != "A" && this.data.estatus != "B"){
      this.toastr.Error("Error en el formulario");
      this.v_nc_estatus = true;
    }else if((((this.data.password.length != 0 || this.keyString.length != 0 || this.cerString.length != 0) && ((this.data.password.length != 0 && this.keyString.length != 0 && this.cerString.length != 0))) ||(this.data.password.length == 0 && this.keyString.length == 0 && this.cerString.length == 0))){
      
      const obj = {
        SCM:this.data,
        cer64:this.cerData,
        key64:this.keyData,
        filename:'uuid'
      }

      this.spinnerService.show();
      this.crudservice.postUpdate(obj).subscribe((data:any) => {
        
        this.spinnerService.hide();
        if(data.mensaje == "ERROR PFX"){
          this.v_nc_PFX = true;
          this.toastr.Error("Error al generar el PFX");
        }else{
          var obj = {
            show : true,
            mensaje:"Edicion exitosa del RFC"
          }
          this.voted.emit(obj);
          
        }
        
        
      },
        error => {
          this.spinnerService.hide();
          this.toastr.Error("Error con el servidor");
        });
        
    }else{
      this.toastr.Error("Error en las credenciales");
      this.v_nc_comprobantes = true;
    }


  }




  getCer(event) {
    this.texto_input_cer = event.target.files[0].name;
    this.files = event.target.files;
    var reader = new FileReader();
    reader.onload = this._handleReaderLoadedCer.bind(this);
    reader.readAsBinaryString(this.files[0]);
  }
  _handleReaderLoadedCer(readerEvt) {
      var binaryString = readerEvt.target.result;
      this.cerData = btoa(binaryString);  // Converting binary string data.
      this.cerString = "Capturado"
  }
  getKey(event) {
    this.texto_input_key = event.target.files[0].name;
    this.files = event.target.files;
    var reader = new FileReader();
    reader.onload = this._handleReaderLoadedKey.bind(this);
    reader.readAsBinaryString(this.files[0]);
  }
  _handleReaderLoadedKey(readerEvt) {
      var binaryString = readerEvt.target.result;
      this.keyData = btoa(binaryString);  // Converting binary string data.
      this.keyString = "Capturado"
  }

  resetBools(){
    this.v_nc_comprobantes = false;
    this.v_nc_estatus = false;
    this.v_nc_razonsocial = false;
    this.v_nc_comprobantes = false;
    this.v_nc_PFX = false;
  }
}
