import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  private httpOptions2 = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  constructor(private http: HttpClient) { }

  public post(type, body) {
    return this.http.post(`${environment.apiUrl}/transactions/Report/${type}`, body, this.httpOptions2);
  }
}
