import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; 
 
import {SharedModule} from '../../../../shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ToastyModule} from 'ng2-toasty'; 

import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { AuthGuardService } from '../../../../shared/auth-guard/auth-guard.service';

import { NgSelectModule } from '@ng-select/ng-select';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner'; 
import { KeysPipe } from 'src/app/pipes/keypipe/keys.pipe';
import { SanitizeHtmlPipe } from 'src/app/pipes/htmlsafe/htmlsafe.pipe';
import { CompaniasConciliacionesComponent } from '../companias/companias.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { ShareComponent } from '../sharecomponent/share.component';

@NgModule({
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgxDatatableModule,
    ToastyModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    NgxPaginationModule
  ],
  declarations: [
    CompaniasConciliacionesComponent,ShareComponent
 //   SanitizeHtmlPipe
  ],
  exports: [ 
    CompaniasConciliacionesComponent, ShareComponent
  ],
  providers: [
    AuthGuardService
  ]
})
export class ConciliacionesModule { }
