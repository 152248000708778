
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NotificacionService } from 'src/app/services/bi/operacion/notificaciones.service';
import { AuthService } from 'src/app/services/self/seguridad/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { PedidoServiceService } from 'src/app/services/bi/comprobantes/pedido-service.service';
import { ConsultapedidoService } from 'src/app/services/bi/comprobantes/consultapedido.service';
@Component({
  selector: 'app-nuevopedido',
  templateUrl: './nuevopedido.component.html',
  styleUrls: ['./nuevopedido.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss'],
})
export class NuevopedidoComponent implements OnInit {
  @Input() Data: any = {type:"default"};
  @Output() padre = new EventEmitter();
  PODate = ""
  PONumber = ""
  VendorParty = ""
  secuencia = " > ";
  actual = "";
  ListaClientes: any = [];
  ListaParentParty: any = [];
  ListaCPM: any = [];
  CPMList: any = [];
  CPM:any = ""
  ParentPartyList = [];
  ParentParty = "";
  ParentPartyObjId = "";
  cliente : any = {};
  RG = "";
  General: any = {};
  Auth: any = {};
  ///
  codigo = ""
  tienda = ""
  st3 = ""
  proveedor = ""
  ///
  ListaCPMS : any = []
  nListaCPM : any = []
  SelectedECPM: any = "0";
  RGList: any = [];
  xRGList: any = [];
  SelectedRG: any = "0";
  PPList: any = [];
  xPPList: any = [];
  SelectedPP: any = "0";
  CHCList: any = [];
  xCHCList: any = [];
  SelectedCHC: any = "0";
  ProvidersList: any = [];
  SelectedProvider:any = "";
  EdiShipType = ""
  POHdrObjectId = 0
  ChangeCPM = false;
  RequestedDate = ""
  
  constructor(private toastr: CommontoastrService , private router: Router, private spinnerService: Ng4LoadingSpinnerService, 
    private AuthService: AuthService, private NotificacionService: NotificacionService,
    private pservice: PedidoServiceService, private pservice2: ConsultapedidoService ) { 
      var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.PODate = yyyy + '-' + mm + '-' + dd;
    this.RequestedDate = yyyy + '-' + mm + '-' + dd;
    }

  ngOnInit() {
    var data = localStorage.getItem('auth');
    if (data != undefined && data != null) {
      this.Auth = JSON.parse(data);
      this.General.IdUser = this.Auth.objectId;
    }

    if(this.Data.type == 'editPO'){
      this.CargarPO();
    }else{
      this.spinnerService.show();
      this.CargarClientes(this.Auth.email);
    }
  
    
  }

  agregar(obj){
    this.cliente = obj;

    if(obj.ediShipToReferenceTypeObjectId > 0){
      this.EdiShipType = obj.ediShipToReferenceTypeObjectId;
      this.CargarMainGrid(obj)
      return;
    }

    this.secuencia = " > " + obj.code + " " + obj.description

    if(this.cliente.rg.length > 1){
      this.actual = "RG";
      return
    }

    //this.secuencia += "  >  " + this.cliente.rg[0].code + " " + this.cliente.rg[0].description

    this.RG = this.cliente.rg[0].rgObjectId
    this.spinnerService.show();
    var body = {
      ECPMObjectId:this.cliente.ecpmObjectid,
      RGObjectId:this.cliente.rg[0].rgObjectId
    }
    
    this.pservice.ParentParty(body).subscribe(data => {
      this.ListaParentParty = data
      this.spinnerService.hide();
      this.ListaParentParty.forEach(e => {
        e.code = e.code.toString().trim()
        e.description = e.description.toString().trim()
        e.display = e.code + " - " +  e.description
      });
      this.ParentPartyList = this.ListaParentParty.map(a => a.display);
      this.actual = 'parentparty';
    },
      error => {
        this.handleError(error);
      });

  }

  volver(){
    if(this.Data.type != 'default'){
      this.padre.emit([]);
      return
    }
    if(this.cliente.rg.length > 1 && this.actual == 'parentparty'){
      var temp = this.secuencia.split(" > ")
      this.secuencia = " > " + temp[1]
      this.ParentParty = ""
      this.actual = "RG";
    }
    else if(this.actual == 'parentparty'){
      this.secuencia = " > "
      this.actual = "";
    }

    else if (this.actual == "RG"){
        this.secuencia = " > "
        this.actual = ""
    }
    else if (this.actual == "cpm"){
      var temp = this.secuencia.split(" > ")
      this.secuencia = " > " + temp[1] + " > " + temp[2]
      this.actual = "parentparty"
      this.ParentParty = ""
    }
    else if (this.actual == "create"){
      //var temp = this.secuencia.split(" > ")
      //this.secuencia = " > " + temp[1] + " > " + temp[2] + " > " + temp[3]
      //this.actual = "cpm"
      this.actual = "MainGrid"
      this.PONumber = ""
      this.SelectedProvider = ""
      this.ProvidersList = [];

    }
    else if(this.actual == "MainGrid"){
      this.SelectedECPM = "0";
      this.RGList = [];
      this.SelectedRG = "0";
      this.PPList = [];
      this.xPPList = [];
      this.SelectedPP = "0";
      this.CHCList = [];
      this.xCHCList = [];
      this.SelectedCHC = "0";
      this.ProvidersList = [];
      this.SelectedProvider = "";
      this.nListaCPM = []
      this.ListaCPMS = []
      this.actual = ""
      this.codigo = ""
      this.st3 = ""
      this.tienda = ""
      this.proveedor = "";
    }

    

  }

  CargarMainGrid(Id){
    console.log(Id);
    this.spinnerService.show();
    var obj = {
      ECPMObjectId:Id.ecpmObjectid,
      RGObjectId:0,
      PPObjectId:0,
      CCObjectId:0,
      Codigo:this.codigo,
      Tienda:0,
      TiendaST3:this.st3,
      Proveedor:this.proveedor,
      type:'GetCPMByEDIShipToReferenceType'
    }

    this.pservice2.PP_NuevoPedido_Queries(obj).subscribe(data => {
      this.SelectedECPM = Id.ecpmObjectid;
      this.RGList = data[0]
      this.PPList = data[1]
      this.xPPList = data[1]
      this.CHCList = data[2]
      this.xCHCList = data[2]
      if(data[3][0]["tienda ST3"] == null){
        this.nListaCPM = data[3]
        this.nListaCPM.forEach(e => {
          var x = e.branchPartyName.toString().trim()
          if(x.length > 0){
              e.code = e.code.toString().trim();
              e.description = e.description.toString().trim();
              e.branchPartyName = e.branchPartyName.toString().trim();
          }
        });
        console.log(this.nListaCPM[0]);
      }else{
        this.ListaCPMS = data[3]
        this.ListaCPMS.forEach(e => {
          var x = e.branchPartyName.toString().trim()
          if(x.length > 0){
              e.description = e.branchPartyName.toString().trim();
          }
        });
      }
      
      
      this.spinnerService.hide();
      this.actual = "MainGrid"
    
    },
      error => {
        this.handleError(error);
      });
  }

  selectCPM(cpm){
    this.ChangeCPM = true;
    console.log(this.EdiShipType)
    console.log(cpm);
    this.CPM = cpm.objectID;
    if(cpm["no de Tienda"] != null){
      this.codigo = cpm["código de Cliente"]
      this.st3 = cpm["no de Tienda ST3"]
      this.tienda = cpm["no de Tienda"]
      this.proveedor = cpm["no de Proveedor"]
      this.SelectedProvider = "selected"
    }else{
      this.codigo = cpm["code"]
      this.st3 = cpm["gln Facturar"]
      this.tienda = cpm["gln"]
      this.proveedor = "0";
      this.SelectedProvider = "selected"
    }

    if(cpm["no de Proveedor"] == 0){
      this.spinnerService.show();
      var obj = {
        ECPMSObjectId:cpm.objectId,
        ECPMObjectId:this.SelectedECPM,
        RGObjectId:0,
        PPObjectId:0,
        CCObjectId:0,
        type:'RecuperarSelectProviders'
      }
      console.log(obj);
      
      this.pservice2.PP_NuevoPedido_Queries(obj).subscribe(data => {
        this.ProvidersList = data;
        this.spinnerService.hide();
        this.SelectedProvider = this.ProvidersList[0].objectId
        //this.actual = "create"
      },
        error => {
          this.handleError(error);
        });
        
    }else if(cpm["no de Proveedor"] != 0){
      console.log(cpm);
      this.ProvidersList = []
      var x = {createdByUserObjectId: 0,
              dateCreated: "2020-04-13T13:17:44.277",
              dateModified: null,
              description: "Proveedor de EDIInfHeader",
              modifiedByUserObjectId: null,
              noDeProveedor: cpm["no de Proveedor"],
              objectId: 9999,
              parentClassId: 27,
              parentObjectId: 17,
              statusObjectId: 1
            }
      this.ProvidersList.push(x)
      this.proveedor = cpm["no de Proveedor"];
      this.SelectedProvider = 9999
        
    }
  }

  CargarClientes(Id:any) {
    this.pservice.Start(Id).subscribe(data => {
      this.ListaClientes = data
      this.spinnerService.hide();
    },
      error => {
        this.handleError(error);
      });
  }

  Filter(){
    this.spinnerService.show();
    var obj = {
      ECPMObjectId:this.SelectedECPM,
      RGObjectId:this.SelectedRG,
      PPObjectId:this.SelectedPP,
      CCObjectId:this.SelectedCHC,
      Codigo:this.codigo,
      Tienda:this.tienda,
      TiendaST3:this.st3,
      //Proveedor:this.proveedor,
      Proveedor:"",
      type:'GetCPMByEDIShipToReferenceType'
    }


    this.pservice2.PP_NuevoPedido_Queries(obj).subscribe(data => {
      this.ListaCPMS = []
      this.nListaCPM = []
      //this.ProvidersList = []
      //this.proveedor = ""

      //this.RGList = data[0]
      //this.PPList = data[1]
      //this.CHCList = data[2]
      if(parseInt(this.EdiShipType) > 1){
        this.ListaCPMS = data [3];
        this.ListaCPMS.forEach(e => {
          var x = e.branchPartyName.trim()
          if(x.length > 0){
              e.description = e.branchPartyName.trim();
          }
        });
      }else{
        this.nListaCPM = data[3]
        this.nListaCPM.forEach(e => {
          var x = e.branchPartyName.trim()
          if(x.length > 0){
              e.code = e.code.trim();
              e.description = e.description.trim();
              e.branchPartyName = e.branchPartyName.trim();
          }
        });
      }
      
      
      this.spinnerService.hide();
    
    },
      error => {
        this.handleError(error);
      });
  }

  private handleError(error: any) {

    this.spinnerService.hide();
    if (error.status == 401) {
      localStorage.removeItem('auth');
      this.router.navigate(['login'])
    }
    this.toastr.Error("Error al cargar la información");

  };

  selectRG(rg){
    
    this.spinnerService.show();
    this.RG = rg.rgObjectId;
    var body = {
      ECPMObjectId:this.cliente.ecpmObjectid,
      RGObjectId:rg.rgObjectId
    }
    this.pservice.ParentParty(body).subscribe(data => {
      this.secuencia += "  >  " + rg.code + " " + rg.description
      this.ListaParentParty = data
      this.spinnerService.hide();
      this.ListaParentParty.forEach(e => {
        e.code = e.code.trim()
        e.description = e.description.trim()
        e.display = e.code + " - " +  e.description
      });
      
      this.ParentPartyList = this.ListaParentParty.map(a => a.display);
      this.actual = 'parentparty';
    },
      error => {
        this.handleError(error);
      });
  }

  selectPP(){
    this.spinnerService.show();
    var body = {
      ECPMObjectId:this.cliente.ecpmObjectid,
      RGObjectId:this.RG,
      PPObjectId:this.ParentPartyObjId
    }
    this.pservice.CustomerPartyMaster(body).subscribe(data => {
      this.ListaCPM = data;

      this.ListaCPM.forEach(e => {
        e.code = e.code.trim()
        e.description = e.description.trim()
        e.display = e.code + " - " +  e.description
      });
      this.CPMList = this.ListaCPM.map(a => a.display);
      this.actual = 'cpm';
    },
      error => {
        this.handleError(error);
      });
  }

  test(event:any){
    this.spinnerService.show();
    event = event.split(" ")
    var index = this.ListaParentParty.findIndex(x => x.code ===event[0]);
    this.ParentPartyObjId = this.ListaParentParty[index].objectID
    var body = {
      ECPMObjectId:this.cliente.ecpmObjectid,
      RGObjectId:this.RG,
      PPObjectId:this.ParentPartyObjId
    }
    this.pservice.CustomerPartyMaster(body).subscribe(data => {
      this.secuencia += " > " + this.ListaParentParty[index].code + " " + this.ListaParentParty[index].description
      this.spinnerService.hide();
      this.ListaCPM = data;

      this.ListaCPM.forEach(e => {
        e.code = e.code.trim()
        e.description = e.description.trim()
        if(e.branchPartyName.length < 1){
          e.display = e.code + " - " +  e.description
        }else{
          e.display = e.code + " - " +  e.branchPartyName
        }
        
      });
      this.CPMList = this.ListaCPM.map(a => a.display);
      this.actual = 'cpm';
    },
      error => {
        this.handleError(error);
      });
  }

  test2(event:any){
    event = event.split(" ")
    var index = this.ListaCPM.findIndex(x => x.code ===event[0]);
    this.CPM = this.ListaCPM[index].objectID
    if(this.ListaCPM[index].branchPartyName.length < 1){
      this.secuencia += " > " + this.ListaCPM[index].code + " " + this.ListaCPM[index].description
    }else{
      this.secuencia += " > " + this.ListaCPM[index].code + " " + this.ListaCPM[index].branchPartyName
    }
    
    this.actual = "create"
  }

  Confirmar(){
    if (this.PONumber.length < 3){
      this.toastr.Error("Ingrese un número de pedido válido.")
      return;
    }
    var time = new Date(this.PODate).getTime() - new Date().getTime();
    var diffDays = Math.ceil(time / (1000 * 60 * 60 * 24));
    if(diffDays > 0 || diffDays < -31){
      this.toastr.Error("La fecha de la orden tiene rango de 30 días a la fecha actual.")
      return;
    }

    console.log(this.SelectedProvider);
    console.log(this.ProvidersList);
    if(this.SelectedProvider.length < 2 && this.ProvidersList.length > 0){
      this.toastr.Error("Seleccione un proveedor de la lista")
      return
    }

    if(this.Data.type != 'default'){
      this.ConfirmarEdicion()
    }
    else{
      var x;
      if(this.ProvidersList.length > 0){
        this.ProvidersList.forEach(e => {
          if(e.objectId == this.SelectedProvider){
            x = e.noDeProveedor
          }
        });
      }else{
        x = this.proveedor.toString();
      }
      if(this.ProvidersList.length == 1){
        x = this.ProvidersList[0].noDeProveedor
      }
      console.log(x);
      var obj = {
          ECPMObjectId:parseInt(this.SelectedECPM),
          EDIShipToReferenceTypeObjectId:this.EdiShipType,
          FechaP:this.PODate,
          CPMObjectId:this.CPM,
          VendorParty:x.toString().trim(),
          Email:this.Auth.email,
          PONumber:this.PONumber,
          BranchParty:this.tienda.toString(),
          BranchPartyEAN:this.st3.toString(),
          POHdrObjectId:0,
          RequestedDeliveryD:this.RequestedDate
      }
      console.log(obj);
      //return de pruebas
      this.spinnerService.show();
      this.pservice.Registrar(obj).subscribe(data => {
        this.spinnerService.hide();
        if(data[0].error){
          this.toastr.Error(data[0].error);
          return;
        }
        console.log(data)
        this.toastr.Success("Pedido " +this.PONumber + " guardado con éxito");
        this.router.navigate(['default/pedidos/pedidosconsulta/'+data[0].poObjectId]);
        this.actual = ""
        this.secuencia = " > "
        this.limpiar()

      },
        error => {
          this.spinnerService.hide();
          this.handleError(error);
        });
    }
  }

  combo(e, type:string){

    console.log(e);
    if(type == "RG"){
      this.xPPList = []
      if(e == 0){
        this.xPPList = this.PPList;
      }else{
        this.PPList.forEach(el => {
          if(el.referenceGroupObjectId == e){
            this.xPPList.push(el);
          }
        });
      }
      this.xCHCList = []
      this.CHCList.forEach(ele => {

        for (let i = 0; i < this.xPPList.length; i++) {
          if(this.xPPList[i].channelCodeObjectID == ele.objectID){
            this.xCHCList.push(ele);
            break;
          }
          
        }

        
        
      });
      
     
    }else if(type == "PP"){
      /*
      this.xPPList = []
      if(e == 0){
        this.xPPList = this.PPList;
      }else{
        this.PPList.forEach(el => {
          if(el.referenceGroupObjectId == e){
            this.xPPList.push(el);
          }
        });
      }
      debugger;
      */
     /*
      console.log(e);
      this.xCHCList = []
      if(e == 0){
        this.xCHCList = this.CHCList;
      }else{
        this.CHCList.forEach(ele => {
          if(ele.channelCodeObjectID == e){
            this.xCHCList.push(ele)
            return
          }
        });
      }
*/

    }
  }

  ConfirmarEdicion(){

    var x;

    if(this.ProvidersList.length > 0){
      console.log(this.SelectedProvider);
      this.ProvidersList.forEach(e => {
        if(e.objectId == this.SelectedProvider){
          x = e.noDeProveedor
        }
      });
    }else{
      x = this.proveedor.toString();
    }
    if(this.ProvidersList.length == 1){
      x = this.ProvidersList[0].noDeProveedor
    }


    var obj = {}
    if(this.ChangeCPM){
        obj = {
          ECPMObjectId:parseInt(this.SelectedECPM),
          EDIShipToReferenceTypeObjectId:this.EdiShipType,
          FechaP:this.PODate,
          CPMObjectId:this.CPM,
          VendorParty:x.toString().trim(),
          Email:this.Auth.email,
          PONumber:this.PONumber,
          BranchParty:this.tienda.toString(),
          BranchPartyEAN:this.st3.toString(),
          POHdrObjectId:this.POHdrObjectId,
          RequestedDeliveryD:this.RequestedDate
        }
    }else{
        obj = {

          ECPMObjectId:parseInt(this.SelectedECPM),
          EDIShipToReferenceTypeObjectId:this.EdiShipType,
          FechaP:this.PODate,
          CPMObjectId:this.Data.customerPartyMasterObjectId,
          VendorParty:x.toString().trim(),
          Email:this.Auth.email,
          PONumber:this.PONumber,
          BranchParty:this.Data.BranchParty.toString(),
          BranchPartyEAN:this.Data.BranchPartyEAN.toString(),
          POHdrObjectId:this.POHdrObjectId,
          RequestedDeliveryD:this.RequestedDate
        }
    }


    //return de pruebas
    
  

    this.spinnerService.show();
    this.pservice.Registrar(obj).subscribe(data => {
      console.log(data);
      var x : any = data
      if(data[0].error && x.length > 0){
        this.toastr.Error(data[0].error);
        return;
      }
      /*
      var x = data;
      x = data; 
      x["POHdrObjectId"] = this.POHdrObjectId
      this.padre.emit(x);
      */
      this.spinnerService.hide();

      
    },
      error => {
        this.spinnerService.hide();
        this.handleError(error);
      });
  }

  CargarPO(){
    console.log("this.Data");
    console.log(this.Data);
    this.SelectedECPM = this.Data.SelectedECPM;
    this.RGList = this.Data.RGList
    this.PPList = this.Data.PPList
    this.xPPList = this.Data.PPList
    this.CHCList = this.Data.CHCList
    this.xCHCList = this.Data.CHCList
    this.nListaCPM = this.Data.nListaCPM
    this.ListaCPMS = this.Data.ListaCPMS
    this.codigo = this.Data.CPMCode.toString().trim()
    this.tienda = this.Data.BranchParty
    this.st3 = this.Data.BranchPartyEAN
    this.ProvidersList = this.Data.ProvidersList
    this.PODate = this.Data.PODate;
    this.PONumber = this.Data.PONumber
    if(this.ProvidersList[0].objectId = 9999){
      this.SelectedProvider = 9999
    }else{
      this.SelectedProvider = this.Data.Vendor.toString();
    }
    
    this.EdiShipType = this.Data.ediShipToReferenceTypeObjectId
    this.CPM = this.Data.customerPartyMasterObjectId
    this.POHdrObjectId = this.Data.POHdrObjectId
    this.actual = 'MainGrid'
    console.log("----------");
    console.log(this.SelectedProvider.toString().length);
    console.log(this.PONumber.length);
    console.log(this.Data.type);
    
  }

  Cancel(){
    if(this.Data.type != 'default'){
      this.SelectedProvider = this.Data.Vendor.toString();
      this.ProvidersList = this.Data.ProvidersList
      this.nListaCPM = this.Data.nListaCPM
      this.ListaCPMS = this.Data.ListaCPMS
      this.CPM = this.Data.customerPartyMasterObjectId
      this.codigo = this.Data.CPMCode.toString().trim()
      this.tienda = this.Data.BranchParty
      this.st3 = this.Data.BranchPartyEAN
      this.RGList = this.Data.RGList
      this.PPList = this.Data.PPList
      this.xPPList = this.Data.PPList
      this.CHCList = this.Data.CHCList
      this.xCHCList = this.Data.CHCList
      
    }
    this.ChangeCPM = false;

  }

  limpiar(){
    this.PONumber = ""
    this.VendorParty = ""
    this.secuencia = " > ";
    this.actual = "";
    this.ListaParentParty = [];
    this.ListaCPM = [];
    this.CPMList = [];
    this.CPM = ""
    this.ParentPartyList = [];
    this.ParentParty = "";
    this.ParentPartyObjId = "";
    this.cliente = {};
    this.RG = "";
  }
}
