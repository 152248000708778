import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CrudrfcService } from 'src/app/services/bi/comprobantes/crudrfc.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';

@Component({
  selector: 'app-nuevacompania',
  templateUrl: './nuevacompania.component.html',
  styleUrls: ['./nuevacompania.component.scss']
})
export class NuevacompaniaComponent implements OnInit {
  @Output() voted = new EventEmitter<object>();
  public showmodal:boolean = false;
  public nc_files: any;
  public nc_filestring: any;
  public nc_keyString: string = "";
  public nc_cerString: string = "";
  public nc_keyData: string = "";
  public nc_cerData: string = "";
  public nc_razonsocial:any="";
  public nc_fechainicio:any="";
  public nc_estatus:any="A";
  public nc_password:any="";
  public nc_rfc:any="";
  public v_nc_estatus:any=false;
  public v_nc_rfc:any=false;
  public v_nc_rfc2:any=false;
  public v_nc_razonsocial:any=false;
  public v_nc_comprobantes:any=false;
  public v_nc_PFX:any=false;

  texto_input_cer:string = "Seleccionar archivo: Certificado (.cer)"
  texto_input_key:string = "Seleccionar archivo: Certificado (.key)"

  constructor(private crudservice:CrudrfcService, private spinnerService: Ng4LoadingSpinnerService,
    private toastr: CommontoastrService) {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.nc_fechainicio = yyyy + '-' + mm + '-' + dd;

  }

  ngOnInit() {
  }


  volver(){
    //this.toastr.Info("Éxito al ingresar la compañia");
    var obj = {
      show : false,
      mensaje:"Edicion exitosa del RFC"
    }
    this.voted.emit(obj);

  }


  SaveChanges(){
    this.resetBools()
    if(this.nc_rfc.length < 9){
      this.toastr.Error("Error en el formulario");
      this.v_nc_rfc = true;
    }else if(this.nc_razonsocial.length < 4){
      this.toastr.Error("Error en el formulario");
      this.v_nc_razonsocial = true;
    }else if(this.nc_estatus != "A" && this.nc_estatus != "B"){
      this.toastr.Error("Error en el formulario");
      this.v_nc_estatus = true;
    }
    else if(this.nc_password.length < 4 || this.nc_keyString.length < 4  || this.nc_cerString.length < 4){
      this.v_nc_comprobantes = true;
    }else{
      var SCM = {
        RFC:this.nc_rfc,
        Razon_Social:this.nc_razonsocial,
        Verificacion_Inicio:this.nc_fechainicio,
        Verificacion_Actual:this.nc_fechainicio,
        password:this.nc_password,
        Estatus:this.nc_estatus,
        Ruta:'uuid',
        Logic:1
      }
      var send = {
        SCM,
        cer64:this.nc_cerData,
        key64:this.nc_keyData,
        filename:'uuid'
      }

      this.spinnerService.show();
      this.crudservice.postCreate(send).subscribe((data:any) => {
        this.spinnerService.hide();
        if(data.mensaje == "Bien"){
          var obj = {
            show : true,
            mensaje:"La compañía fue agregada correctamente"
          }
          this.voted.emit(obj);
        }else if(data.mensaje == "ERROR PFX"){
          this.v_nc_PFX = true;
          this.toastr.Error("Error con la generación del PFX");
        }
        else if(data.mensaje == "EXISTE"){
          this.v_nc_rfc2 = true;
          this.toastr.Error("El RFC ya se encuentra registrado");
        }
      },
        error => {
          this.spinnerService.hide();
          this.toastr.Error("Error con el servidor");
          console.log(error);
        });
    }
    
    


  }




  getCer(event) {
    this.texto_input_cer = event.target.files[0].name;
    this.nc_files = event.target.files;
    var reader = new FileReader();
    reader.onload = this._handleReaderLoadedCer.bind(this);
    reader.readAsBinaryString(this.nc_files[0]);
  }
  _handleReaderLoadedCer(readerEvt) {
      var binaryString = readerEvt.target.result;
      this.nc_cerData = btoa(binaryString);  // Converting binary string data.
      this.nc_cerString = "Capturado"
  }
  getKey(event) {
    this.texto_input_key = event.target.files[0].name;
    this.nc_files = event.target.files;
    var reader = new FileReader();
    reader.onload = this._handleReaderLoadedKey.bind(this);
    reader.readAsBinaryString(this.nc_files[0]);
  }
  _handleReaderLoadedKey(readerEvt) {
      
      var binaryString = readerEvt.target.result;
      this.nc_keyData = btoa(binaryString);  // Converting binary string data.
      this.nc_keyString = "Capturado"
  }

  resetBools(){
    this.v_nc_rfc2 = false;
    this.v_nc_comprobantes = false;
    this.v_nc_estatus = false;
    this.v_nc_rfc = false;
    this.v_nc_razonsocial = false;
    this.v_nc_comprobantes = false;
    this.v_nc_PFX = false;
  }

  
}
