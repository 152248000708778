import { Component, OnInit } from '@angular/core';
import { SeguridadService } from "src/app/services/bi/seguridad/seguridad.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CommontoastrService } from "src/app/shared/toastr/commontoastr.service";

@Component({
  selector: 'app-applicationsettings',
  templateUrl: './applicationsettings.component.html',
  styleUrls: ['./applicationsettings.component.scss',
    "../../../../../assets/icon/icofont/css/icofont.scss"]
})
export class ApplicationsettingsComponent implements OnInit {
  listaAppSettings: any = [];
  editar: any = 0;
  itemSeleccionado: boolean = true;
  rowSeleccionado: any = {};
  listaGrid: any = []
  listaBusquedaGrid: any = []
  buscar : any = ""
  constructor(private seguridadService: SeguridadService,
    private spinnerService: Ng4LoadingSpinnerService,private toastr:CommontoastrService) {
    this.cargaInicial();
  }

  ngOnInit() {
  }
  onSort(event){
    console.log(event);
  }
  cargaInicial() {
    this.spinnerService.show();
    this.seguridadService.ObtenerAppSettingsLista({}).subscribe(data => {
      this.spinnerService.hide();
      this.listaAppSettings = data;
      for (let i = 0; i < this.listaAppSettings.length; i++) {
        this.listaAppSettings[i]['configuracionAplicacionId'] = this.listaAppSettings[i]['configuracionAplicacionId'].toString()
      }
      this.listaGrid = this.listaAppSettings
      this.listaBusquedaGrid = this.listaAppSettings
      console.log(data)
      console.log(this.listaAppSettings[0]['configuracionAplicacionId'].toString());
    },
      error => {
        console.log(error)
      })
  }

  reaload() {
    this.cargaInicial();
    this.toastr.Success("Registros actualizados")
    this.buscar = ""
  }

  editarItem() {
    this.editar = 1;
  }

  guardarCambios() {
    var body = this.rowSeleccionado;
    this.spinnerService.show();
    this.seguridadService.GuardarCambiosAppSettings(body).subscribe(data => {
      this.spinnerService.hide();
      this.editar = 0;
      console.log(data)
    },
      error => {
        console.log(error)
      })
  }

  detalle(row) {
    this.itemSeleccionado = false;
    this.rowSeleccionado = row;
    console.log(row)
  }
  Buscar(){
    
    this.listaBusquedaGrid = []
    for (let i = 0; i < this.listaGrid.length; i++) {
      
      for (let x = 0; x < Object.keys(this.listaGrid[0]).length; x++) {
        var propiedades = Object.keys(this.listaGrid[i])
        var propiedad = propiedades[x]
        var valor = String(this.listaGrid[i][propiedad]).toLocaleUpperCase()
       
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGrid.push(this.listaGrid[i])
          break
        }
          
      }
    }

  }

}
