import { Component, OnInit } from '@angular/core';
import { SeguridadService } from "src/app/services/bi/seguridad/seguridad.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { DatePipe } from "@angular/common";
import { CommontoastrService } from "src/app/shared/toastr/commontoastr.service";

@Component({
  selector: 'app-applicationsettingsbyprofile',
  templateUrl: './applicationsettingsbyprofile.component.html',
  styleUrls: ['./applicationsettingsbyprofile.component.scss',
    "../../../../../assets/icon/icofont/css/icofont.scss"]
})
export class ApplicationsettingsbyprofileComponent implements OnInit {

  listaAppSettingsProfile: any = [];
  listaBusquedaGrid: any = []
  listaGrid: any = []
  buscar : any = ""
  editar: any = 0;
  agregar: any =0;

  grupoId: any = 0;
  AgrupoId: any = 0
  configId: any = 0;
  AconfigId: any = 0;

  itemSeleccionado: boolean = true;
  rowSeleccionado: any = {};

  listaGrupos: any = [];
  listaConfig: any = [];
  constructor(private seguridadService: SeguridadService,private toastr:CommontoastrService,
    private spinnerService: Ng4LoadingSpinnerService,) {
    this.cargaInicial();
  }

  ngOnInit() {
  }

  cargaInicial() {
    this.buscar = ""
    this.spinnerService.show();
    this.seguridadService.ObtenerAppSettingsByProfileLista({}).subscribe(data => {
      console.log("datos cargados");
      this.listaAppSettingsProfile = data;
      this.listaBusquedaGrid = data;
      /* this.listaAppSettingsProfile.forEach(e => {
        this.listaGrid.push(this.listaAppSettingsProfile[e]['group'].name)
      }); */
      this.cargaCombos();
      console.log(data);
      // console.log(data)
    },
      error => {
        console.log(error)
      })

  }

  guardarCambios() {
    var body = {
      MvcId: this.rowSeleccionado.mvcId,
      GroupId: parseInt(this.grupoId),
      ConfiguracionAplicacionId: this.configId
    }
    console.log(body)
    this.seguridadService.GuardarCambiosAppSettingsByProfile(body).subscribe(data => {
      this.spinnerService.hide();
      this.editar = 0;
      this.cargaInicial();
      this.toastr.Success("Cambios Guardados")
    },
      error => {
        console.log(error)
        this.spinnerService.hide();
      })
  }

  agregarP() {
    this.spinnerService.show()
    console.log(this.grupoId);
    var body = {
      GroupId: parseInt(this.AgrupoId),
      ConfiguracionAplicacionId: this.AconfigId
    }
    console.log("agregarP");
    console.log(body)
    this.seguridadService.AgregarAppSettingsByProfile(body).subscribe(data => {
      this.cargaInicial();
      console.log(data);
      this.editar = 0;
      this.toastr.Success("Agregado Correctamente!")
      this.AgrupoId = 0
      this.AconfigId = 0
    },
      error => {
        console.log(error)
        this.spinnerService.hide();
      })
      
  }

  delete() {
    var body = this.rowSeleccionado
    // console.log(body)
    this.seguridadService.BorrarAppSettingsByProfile(body).subscribe(data => {
      this.cargaInicial();
      console.log(data)
      this.toastr.Success("Eliminado Correctamente!")
    },
      error => {
        console.log(error)
        this.spinnerService.hide()
      })
    // console.log(body)
  }

  cargaCombos() {
    this.seguridadService.ObtenerComboGrupos({}).subscribe(data => {
      this.listaGrupos = data;
      this.seguridadService.ObtenerComboConfig({}).subscribe(data => {
        this.listaConfig = data;
        this.spinnerService.hide();
      },
        error => { console.log(error) })

    }, error => { console.log(error) })

    
  }

  detalle(row) {
    this.itemSeleccionado = false;
    this.rowSeleccionado = row;
    this.grupoId = row.groupId;
    this.configId = row.configuracionAplicacionId
  }

  reaload() {
    this.cargaInicial();
    this.toastr.Success('Registros actualizados')
  }

  Buscar(){
    
    this.listaBusquedaGrid = []
    for (let i = 0; i < this.listaAppSettingsProfile.length; i++) {
      
      for (let x = 0; x < Object.keys(this.listaAppSettingsProfile[0]).length; x++) {
        var propiedades = Object.keys(this.listaAppSettingsProfile[i])
        var propiedad = propiedades[x]
        var prop = Object.keys(this.listaAppSettingsProfile[i][propiedad])
        var valor = String(this.listaAppSettingsProfile[i][propiedad].name).toLocaleUpperCase()
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGrid.push(this.listaAppSettingsProfile[i])
          break
        }
        valor = String(this.listaAppSettingsProfile[i][propiedad].aplicacion).toLocaleUpperCase()
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGrid.push(this.listaAppSettingsProfile[i])
          break
        }
        valor = String(this.listaAppSettingsProfile[i][propiedad].apartado).toLocaleUpperCase()
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGrid.push(this.listaAppSettingsProfile[i])
          break
        }
        valor = String(this.listaAppSettingsProfile[i][propiedad].contenidoDelCampo).toLocaleUpperCase()
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGrid.push(this.listaAppSettingsProfile[i])
          break
        }
          
      }
    }

  }
  /* Buscar(){
    
    this.listaBusquedaGrid = []
    for (let i = 0; i < this.listaAppSettingsProfile.length; i++) {
      
      for (let x = 0; x < Object.keys(this.listaAppSettingsProfile[0]).length; x++) {
        var propiedades = Object.keys(this.listaAppSettingsProfile[i])
        var propiedad = propiedades[x]
        var prop = Object.keys(this.listaAppSettingsProfile[i][propiedad])
        console.log(prop);
        for (let y = 0; y < 11; y++) {
          var p = prop[y]
          var valor = String(this.listaAppSettingsProfile[i][propiedad].p).toLocaleUpperCase()
          console.log("valor");
          console.log(valor);
          if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
            this.listaBusquedaGrid.push(this.listaAppSettingsProfile[i])
            break
          }
        }
      }
    }

  } */

  cancelar(){
    this.editar = 0
    this.grupoId = 0
    this.configId = 0
    this.AgrupoId = 0
    this.AconfigId = 0
  }

}
