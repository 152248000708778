import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estados'
})
export class EstadosPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    console.log(value)
    switch (value) {
      case "5000":
        return "Solicitud recibida con éxito"
        
      case "5002":
        return "Límite máximo de solicitudes con mismos parámetros"
        
    
      case "5003":
        return "Tope máximo de CFDI por solicitud"
        
    
      case "5004":
        return "No se generaron paquetes por falta de información"
       
      case "5005":
        return "Solicitud duplicada"
        
    
      default:
        break;
    }
  }

}
