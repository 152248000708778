import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class CrudrfcService {

  private httpOptions2 = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  constructor(private http: HttpClient) { }


  public postUpdate(rfc) {
    const myId = uuid.v4();
    rfc.filename = myId;
    return this.http.post(`${environment.apiUrl}/transactions/File`,rfc)
    .map(res => res);
  }

  public postCreate(data){

    const myId = uuid.v4();
    data.filename = myId;
    data.SCM.ruta = myId;

    return this.http.post(`${environment.apiUrl}/transactions/File/create`,data);

  }
}
