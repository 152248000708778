import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PagosService } from 'src/app/services/bi/pagos/pagos.service';
import { AuthService } from 'src/app/services/self/seguridad/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss']
})
export class EditarComponent implements OnInit {
  selected:any = []
  @Input() selectedDS : any = "";
  @Input() titulo : any;
  listaPagos : any = []
  filtershow : any = true
  elementos : any = {};
  factura:any = ""
  suma : any = 0

  @Output() padre = new EventEmitter();
  //@Input() listaGridPrincipal : any;
  @Input() selectedPago : any;
  @Input() filtrosP : any = [];

 
  
  //General
  timefired : any  = null;
  Auth: any = {};
  visibleCards: any = [true,true,false,true,true]
  listaGridPrincipal : any = []
  listaBusquedaGridPrincipal: any = []
  buscar: any = ""

  //Card 2
  filtros = {
    listaECPM :  [],
    selectedECPM :  0,
    listaCPM :  [],
    selectedCPM :  0,
    selectedClientType :  1,
    PDate1 :  "",
    PDate2 :  "",
    importemin :  "",
    importemax :  "",
    montoActual :  0,
    listaRG :  [],
    selectedRG :  0,
    listaDT :  [],
    selectedDT :  0,
    listaDST :  [],
    selectedDST :  0,
    listaDSO :  [],
    selectedDSO :  0,
    listaIS :  [],
    selectedIS :  0,
    listaPP :  [],
    xlistaPP :  [],
    selectedPP :  0,
  }

  filtrosAplicados = {
    listaECPM :  [],
    selectedECPM :  0,
    listaCPM :  [],
    selectedCPM :  0,
    selectedClientType :  1,
    PDate1 :  "",
    PDate2 :  "",
    importemin :  "",
    importemax :  "",
    montoActual :  0,
    listaRG :  [],
    selectedRG :  0,
    listaDT :  [],
    selectedDT :  0,
    listaDST :  [],
    selectedDST :  0,
    listaDSO :  [],
    selectedDSO :  0,
    listaIS :  [],
    selectedIS :  0,
    listaPP :  [],
    xlistaPP :  [],
    selectedPP :  0,
  }
  
  constructor(private toastr: CommontoastrService, private pservice:PagosService, private spinnerService: Ng4LoadingSpinnerService) { 
    
    var data = localStorage.getItem('auth');
      if (data != undefined && data != null) {
        this.Auth = JSON.parse(data);
      }
    
  }

  ngOnInit() {
    if(this.titulo.length > 0){
      this.titulo += " - Relacionar a documento"
    }
    if(this.filtrosP != []){
      this.filtros.selectedECPM = this.filtrosP.selectedECPM
      this.filtros.listaPP = this.filtrosP.listaPP
      this.filtros.xlistaPP = this.filtrosP.xlistaPP
      this.filtros.listaRG = this.filtrosP.listaRG
      this.filtros.selectedPP = this.filtrosP.selectedPP
      this.filtros.selectedRG = this.filtrosP.selectedRG
      this.filtros.selectedDST = this.filtrosP.selectedDST
    }
    this.listaPagos = []
    this.listaPagos.push(this.selectedPago)
    this.cargaInicial()
      

  }
  cargaInicial(){
    this.spinnerService.show()
    this.pservice.ObtenerCombos({}).subscribe(data => {
      this.filtros.listaECPM = data['ecpm']
      this.filtros.listaDT = data['dt']
      this.filtros.listaDST = data['dSt']
      this.filtros.listaDSO = data['dSo']
      this.filtros.listaIS = data['IS']
      if(this.selectedDS == ""){
        this.spinnerService.hide()
      }else{
        this.filtrar()
      }

    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });
  }

  filtrar(){
    var id = 0;
    if(this.selectedPago['Folio de Documento Financiero'] == undefined){
      id = this.selectedPago.Id
    }else{
      id = this.selectedPago['Folio de Documento Financiero']
    }
    if(this.filtros.selectedECPM == NaN || this.filtros.selectedECPM == null)
      this.filtros.selectedECPM = 0

      if(this.filtros.selectedECPM == NaN || this.filtros.selectedECPM == null)
      this.filtros.selectedECPM = 0
      this.filtrosAplicados.selectedECPM = this.filtros.selectedECPM;
    

    this.filtrosAplicados.listaECPM.push(this.filtros.listaECPM[this.filtros.listaECPM.findIndex(x => x.objectId == this.filtros.selectedECPM)])
    this.filtrosAplicados.listaPP = this.filtros.listaPP
    this.filtrosAplicados.xlistaPP = this.filtros.xlistaPP
    this.filtrosAplicados.listaRG = this.filtros.listaRG
    this.filtrosAplicados.selectedCPM = this.filtros.selectedCPM;
    this.filtrosAplicados.selectedRG = this.filtros.selectedRG;
     // @ts-ignore
    this.filtrosAplicados.selectedPP = parseInt(this.filtros.selectedPP);
    this.filtrosAplicados.selectedDT = this.filtros.selectedDT;
    this.filtrosAplicados.selectedDST = this.filtros.selectedDST;
    this.filtrosAplicados.selectedDSO = this.filtros.selectedDSO;
    this.filtrosAplicados.PDate1 = this.filtros.PDate1==""?'2000-01-01':this.filtros.PDate1
    this.filtrosAplicados.PDate2 = this.filtros.PDate2==""?'2022-01-01':this.filtros.PDate2
    // @ts-ignore
    this.filtrosAplicados.Monto1 = this.filtros.Monto1==""?0:parseFloat(this.filtros.Monto1)
    // @ts-ignore
    this.filtrosAplicados.Monto2 = this.filtros.Monto2==""?0:parseFloat(this.filtros.Monto2)
    // falta IS : this.filtros.SelectedIS,
    var body = {
      ECPM:this.filtros.selectedECPM,
      RFC: this.selectedPago.RFC,
      CPM:this.filtros.selectedCPM,
      RG:this.filtros.selectedRG,
      PP:this.filtros.selectedPP,
       // @ts-ignore
      DT:parseInt(this.filtros.selectedDT),
      DSt:this.filtros.selectedDST,
      DSo:this.filtros.selectedDSO,
      Factura:this.factura,
      PDate1:this.filtros.PDate1==""?'2000-01-01':this.filtros.PDate1,
      PDate2:this.filtros.PDate2==""?'2022-01-01':this.filtros.PDate1,
      importemin:this.filtros.importemin==""?0:parseFloat(this.filtros.importemin),
      importemax:this.filtros.importemax==""?90000000:parseFloat(this.filtros.importemax)
    }
    console.log(body);
    this.spinnerService.show()
    
    this.pservice.ObtenerRelacionesPaymentDTL(body).subscribe(data => {
      console.log(data);
      this.listaGridPrincipal = data[0]
      this.listaBusquedaGridPrincipal = data[0]
      
      this.spinnerService.hide()
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });
  }
  
  toggleCard(e){
    this.visibleCards[e] = !this.visibleCards[e]
  }

  volver(){
    this.padre.emit(this.suma);
  }

  onSelect(e:any){
    this.selected.forEach(es => {
      var pos = this.listaBusquedaGridPrincipal.map(function(e) { return e.Id; }).indexOf(es.Id);
      if(this.listaBusquedaGridPrincipal[pos]['montoManual'] == undefined || this.listaBusquedaGridPrincipal[pos]['montoManual'] == 0){
        this.listaBusquedaGridPrincipal[pos]['montoManual'] = this.listaBusquedaGridPrincipal[pos]['Importe Abierto']
      }
    });
    //e['montoManual'] = e['Importe Abierto']
    this.elementos = e.selected
  }

  updateValue(e,index, value){
    var pos = this.listaBusquedaGridPrincipal.map(function(e) { return e.Id; }).indexOf(value.Id);
    this.listaBusquedaGridPrincipal[index]['montoManual'] = e.target.value
  }

  test(e:any){
    clearTimeout(this.timefired);
    var x = this.spinnerService;
    var y = this.pservice;
    var z = this
    var obj = {
        EDI:0,
        RG:0,
        PP:0,
        texto:e.term
    }
    this.timefired = setTimeout(function () {
      
      
      x.show()
      y.ObtenerClienteCPM(obj).subscribe(data => {
        x.hide()
        console.log(data);
        // @ts-ignore 
        z.filtros.listaCPM=data
        
       
        
        
      },
        error => {
          x.hide()
          console.log("Error");
          console.log(error);
          
          //this.handleError(error);
        });
    }, 2000);
  }

  /*filtrar(){
    if(this.filtros.selectedCPM == NaN)
      this.filtros.selectedCPM = 0
    var body = {
      RFC: this.selectedPago.RFC,
      CPM:this.filtros.selectedCPM,
      RG:this.filtros.selectedRG,
      PP:this.filtros.selectedPP,
      DT:this.filtros.selectedDT,
      DSt:this.filtros.selectedDST,
      DSo:this.filtros.selectedDSO,
      Factura:this.factura,
      PDate1:this.filtros.PDate1==""?'2000-01-01':this.filtros.PDate1,
      PDate2:this.filtros.PDate2==""?'2022-01-01':this.filtros.PDate1,
      importemin:this.filtros.importemin==""?0:parseFloat(this.filtros.importemin),
      importemax:this.filtros.importemax==""?90000000:parseFloat(this.filtros.importemax)
    }

    console.log(body);
    this.spinnerService.show()
    
    this.pservice.ObtenerRelacionesPaymentDTL(body).subscribe(data => {
      console.log(data[0]);
      this.listaGridPrincipal = data[0]
      this.spinnerService.hide()
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        //this.handleError(error);
      });
  }*/

  agregar(){
    this.suma = 0;
    this.selected.forEach(e => {
      for (let i = 0; i < this.listaBusquedaGridPrincipal.length; i++) {
        if(this.listaBusquedaGridPrincipal[i].Id == e.Id){
          /*
          if(this.listaGridPrincipal[i]['montoManual'] == undefined){
            this.toastr.Error("Es necesario capturar el monto en los campos seleccionados")
          }
          e['montoManual'] = parseFloat(this.listaGridPrincipal[i]['montoManual'])
          */
          this.suma += parseFloat(this.listaBusquedaGridPrincipal[i]['montoManual'])
          continue
        }
        
      }
    });
    if(this.suma > this.selectedPago){
      this.toastr.Error("La suma de los importes es mayor al importe pendiente.")
      return
    }
    var data = []
    var id = 0;
    if(this.selectedPago['Folio de Documento Financiero'] == undefined){
      id = this.selectedPago.Id
    }else{
      id = this.selectedPago['Folio de Documento Financiero']
    }
    this.selected.forEach(e => {
      var obj = {
        UserObjectId:this.Auth.objectId,
        ParentObjectId:id,
        Amount:parseFloat(e.montoManual),
        InvoiceObjectId:e.Id
      }
      console.log(parseFloat(e.montoManual));
      data.push(obj)
    });
    
    this.spinnerService.show()
    this.pservice.ObtenerRelacionesPaymentDTLInsertRel(data).subscribe(data => {
      console.log(data);
      if(data[0][0].exito){
        this.toastr.Success(data[0][0].exito)
        
      }else{
        //this.spinnerService.hide()
        this.toastr.Error(data[0][0].error)
      }

      this.volver()
      //this.padre.emit("terminado");
      
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });

  }

  accion(){
    if(this.selected.length > 0){
      this.selected = []
    }else{
      this.listaBusquedaGridPrincipal.forEach(e => {
        e['montoManual'] = e['Importe Abierto']
      });

      this.selected = this.listaBusquedaGridPrincipal
    }
  }

  showFilters(e:boolean){
    this.filtershow = e
  }
  Buscar(){
    
    this.listaBusquedaGridPrincipal = []
    for (let i = 0; i < this.listaGridPrincipal.length; i++) {
      
      for (let x = 0; x < Object.keys(this.listaGridPrincipal[0]).length; x++) {
        var propiedades = Object.keys(this.listaGridPrincipal[i])
        var propiedad = propiedades[x]
        var valor = String(this.listaGridPrincipal[i][propiedad]).toLocaleUpperCase()
       
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGridPrincipal.push(this.listaGridPrincipal[i])
          break
        }
          
      }
    }

  }
}
