import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceService } from 'src/app/shared/resource.service';
import { WINDOW } from '../../../components/bi/timbrado/windows.providers';

@Injectable({
    providedIn: 'root'
})

export class LayoutService {
    public fromSharedService: any = {};

    constructor(private api: ResourceService, private router: Router, @Inject(WINDOW) private window: Window,) { }

    getHostname(): string {
        return this.window.location.hostname;
    }
    Get(data: any) {
        return this.api.get('payments', data);
    }

    ObtenerClienteEDI(data: any) {
        return this.api.get('payments/payments', data);
    }

    ObtenerCombosRGyPP(data) {
        
        return this.api.getById('payments/layout', data);
    }

    ObtenerComboSucursal(data) {
        return this.api.getById('payments/layout/sucursales', data);
    }

    ObtenerClienteCPM(data: any) {
        return this.api.post('payments/payments', data);
    }

    ObtenerListaGrid(data: any) {
        return this.api.post('payments/layout/listView', data);
    }

    ObtenerListaSP(data: any) {
        return this.api.get('payments/layout/SP', data)
    }

    ObtenerMDM(data: any) {
        return this.api.get('payments/layout/MDM', data)
    }

    ObtenerConsultaForms(data: any) {
        return this.api.post('payments/layout/getQuery', data);
    }

    ObtenerConsultaModales(data: any) {
        return this.api.post('payments/layout/modales', data);
    }

    ObtenerIconos(data) {
        return this.api.getById('payments/layout/icons', data)
    }

    ObtenerResumen(data) {
        return this.api.getById('payments/layout/resumen', data)
    }

    GuardarComprobanteComercial(data: any) {
        return this.api.put('payments/layout/comComercial', data)
    }

    GuardarComprobanteFiscal(data: any) {
        return this.api.put('payments/layout/comFiscal', data)
    }

    GuardarCfdiRelacionados(data: any) {
        return this.api.put('payments/layout/cfdiRelacionados', data)
    }

    GuardarEmisorCCE(data: any) {
        return this.api.put('payments/layout/emisorCCE', data)
    }

    GuardarEmisorComercial(data: any) {
        return this.api.put('payments/layout/emisorComercial', data)
    }

    GuardarEmisorExpedicion(data: any) {
        return this.api.put('payments/layout/emisorExpedicion', data)
    }

    GuardarEmisorFiscal(data: any) {
        return this.api.put('payments/layout/emisorFiscal', data)
    }

    GuardarConceptoComercial(data: any) {
        return this.api.put('payments/layout/conceptoComercial', data)
    }

    GuardarConceptoFiscal(data: any) {
        return this.api.put('payments/layout/conceptoFiscal', data)
    }

    GuardarConceptoTrasladoComercial(data: any) {
        return this.api.put('payments/layout/conceptoTrasladoComercial', data)
    }

    GuardarConceptoTrasladoFiscal(data: any) {
        return this.api.put('payments/layout/conceptoTrasladoFiscal', data)
    }

    GuardarReceptorComercial(data: any) {
        return this.api.put('payments/layout/receptorComercial', data)
    }

    GuardarReceptorEntrega(data: any) {
        return this.api.put('payments/layout/receptorEntrega', data)
    }

    GuardarReceptorFiscal(data: any) {
        return this.api.put('payments/layout/receptorFiscal', data)
    }

    GuardarInformacionAduaneraTercero(data: any) {
        return this.api.put('payments/layout/informacionAduaneraTercero', data)
    }

    GuardarInformacionFiscalTercero(data: any) {
        return this.api.put('payments/layout/informacionFiscalTercero', data)
    }

    GuardarParteTercero(data: any) {
        return this.api.put('payments/layout/parteTercero', data)
    }

    GuardarPorCuentaDeTercerosFiscal(data: any) {
        return this.api.put('payments/layout/porCuentaDeTercerosFiscal', data)
    }

    GuardarImpuestosFiscal(data: any) {
        return this.api.put('payments/layout/impuestosFiscal', data)
    }

    GuardarImpuestoTrasladoTercero(data: any) {
        return this.api.put('payments/layout/impuestoTrasladoTercero', data)
    }

    GuardarImpuestoTrasladoFiscal(data: any) {
        return this.api.put('payments/layout/impuestoTrasladoFiscal', data)
    }

    GuardarComercioExterior(data: any) {
        return this.api.put('payments/layout/comercioExterior', data)
    }

    GuardarDestinatarioCCE(data: any) {
        return this.api.put('payments/layout/destinatarioCCE', data)
    }
    GuardarDomicilioDestinatarioCCE(data: any) {
        return this.api.put('payments/layout/domicilioDestinatarioCCE', data)
    }
    GuardarDomicilioEmisorCCE(data: any) {
        return this.api.put('payments/layout/domicilioEmisorCCE', data)
    }
    GuardarDomicilioReceptorCCE(data: any) {
        return this.api.put('payments/layout/domicilioReceptorCCE', data)
    }
    GuardarPropietarioCE(data: any) {
        return this.api.put('payments/layout/propietarioCCE', data)
    }
    GuardarReceptorCCE(data: any) {
        return this.api.put('payments/layout/receptorCCE', data)
    }
    GuardarDescripcionESCPCCE(data: any) {
        return this.api.put('payments/layout/descripcionESCPCCE', data)
    }
    GuardarLeyendasCCE(data: any) {
        return this.api.put('payments/layout/leyendasCCE', data)
    }
    GuardarMercanciasCCE(data: any) {
        return this.api.put('payments/layout/mercanciasCCE', data)
    }
    GuardarInvLeyenda(data: any) {
        return this.api.put('payments/layout/leyenda', data)
    }
    GuardarInvLeyendasFiscales(data: any) {
        return this.api.put('payments/layout/leyendasFiscales', data)
    }
    GuardarAddenda(data: any) {
        return this.api.put('payments/layout/addenda', data)
    }
    GuardarServiciosDistribucion(data: any) {
        return this.api.put('payments/layout/serviciosDistribucion', data)
    }
    GuardarServiciosPAC(data: any) {
        return this.api.put('payments/layout/serviciosPAC', data)
    }


    ObtenerCatalogosSegmentos(data) {
        return this.api.get('payments/layout/seguridad/catalogos', data)
    }

    ObtenerComboUsuarios(data) {
        return this.api.get('payments/security/getComboUsers', data)
    }

    ObtenerComboGrupos(data) {
        return this.api.get('payments/security/getComboGroups', data)
    }

    ObtenerCamposTab(data) {
        return this.api.post('payments/layout/seguridad/getCamposTab', data)
    }

    ObtenerTabSegmentos(data) {
        return this.api.getById('payments/layout/seguridad', data)
    }
    GuardarTabSegmentos(data : any){
        return this.api.put('payments/layout/seguridad/saveTabSegmentos', data)
    }
    ObtenerTabSegmentosHab(data){
        return this.api.get('payments/layout/tabSegmentosHab',data)
    }

    ObtenerCamposTabWithFilter(data) {
        return this.api.post('payments/layout/seguridad/getCamposTabFilter', data)
    }

    GuardarUsuarioAtributosRelacion(data) {
        return this.api.post('payments/layout/seguridad/saveRelation', data)
    }

    ObtenerPermisosCampos(data) {
        return this.api.post('payments/layout/seguridad/getpermits', data)
    }

    ObtenerClaveDocs(data) {
        return this.api.get('payments/layout/DocumentoClave', data)
    }

    ObtenerHabilitado(data) {
        return this.api.post('payments/layout/seguridadClaveDoc', data)
    }

    RetimbrarDoc(data) {
        return this.api.post('payments/layout/retimbrar', data)
    }

    ReprocesoDocumento(data) {
        return this.api.post('payments/layout/reprocesoDocumento', data)
    }
    MDMCatalogClientes(data){
        return this.api.post('payments/layout/catalagoMaestro', data)
    }

    DocumentosFiscales(data){
        return this.api.post('payments/layout/documentosFiscales', data)
    }



}