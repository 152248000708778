import { Component, OnInit } from '@angular/core';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificacionService } from 'src/app/services/bi/operacion/notificaciones.service';
import { AuthService } from 'angular-6-social-login';
import { ConsultapedidoService } from 'src/app/services/bi/comprobantes/consultapedido.service';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.scss','../../../../../assets/icon/icofont/css/icofont.scss']
})
export class ConsultaComponent implements OnInit {
  actual = 'b';
  General: any = {};
  Auth: any = {};
  ListaPedidos : any = []
  ListaDetalles:any = []
  Medidas : any = []
  resumen : any = []
  PO : any = {}
  Data = {type:"default", POHdrObjectId:0, ProvidersList:[], PODate:'', Vendor:'', SelectedECPM:'', RGList:[], PPList:[], CHCList:[], nListaCPM:[], 
  ListaCPMS:[], BranchParty:"", BranchPartyEAN:"", PONumber:"", CPMCode:"", ediShipToReferenceTypeObjectId:0, customerPartyMasterObjectId:0}

  DataRecursos : any = []
  //Filtros
  PODate1 : any = '2020-01-01'
  PODate2 : any = '2020-01-01'
  PONumber : any = ""
  ListaOrderTypes: any = []
  OrderType : any = 2;
  constructor(private toastr: CommontoastrService , private router: Router, private spinnerService: Ng4LoadingSpinnerService, 
    private AuthService: AuthService, private NotificacionService: NotificacionService,
    private pservice: ConsultapedidoService, private activatedRoute: ActivatedRoute) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      this.PODate2 = yyyy + '-' + mm + '-' + dd;
     }

    ngOnInit() {
      var data = localStorage.getItem('auth');
      if (data != undefined && data != null) {
        this.Auth = JSON.parse(data);
        this.General.IdUser = this.Auth.objectId;
      }

      this.activatedRoute.params.subscribe(params => {
        if(params["id"]){
          this.NuevoRecursoIndividual(params["id"])
        }else{
          this.spinnerService.show();
          this.CargarPedidos();
        }
      });

    }

    NuevoRecursoIndividual(Id:any){
      this.spinnerService.show();
      var obj = {
        ObjectId:Id,
        type:'Validate',
        ObjectId2: this.Auth.objectId
      }
      this.pservice.PortalQueries(obj).subscribe(data => {
        this.spinnerService.hide();
        if(data[0].error){
          this.router.navigate(['default/pedidos/pedidosconsulta'])
          return
        }
        console.log(data);
        this.actual = "principal"
        this.PO = data[0][0]
        this.DataRecursos = data[1]
        if(data[0].diff < 2){
          this.toastr.Success("El pedido " + data[0].poNumber + " fue registrado con éxito.")
        }
      },
        error => {
          this.handleError(error);
        });
    }

    CargarRecursos(Id:any) {




      this.Data = {type:"default", POHdrObjectId:0, ProvidersList:[], PODate:'', ediShipToReferenceTypeObjectId:0, Vendor:'', SelectedECPM:'', customerPartyMasterObjectId:0, 
      RGList:[], PPList:[], CHCList:[], nListaCPM:[], ListaCPMS:[], BranchParty:"", BranchPartyEAN:"", PONumber:"", CPMCode:""}


      this.spinnerService.show();
      this.PO = Id
      this.PO['total'] = this.PO.subtotal + this.PO.ieps + this.PO.iva
      this.PO['total'] = (Math.round(this.PO['total'] * 100) / 100).toFixed(2);
      this.PO['subtotal'] = (Math.round(this.PO['subtotal'] * 100) / 100).toFixed(2);
      this.PO['ieps'] = (Math.round(this.PO['ieps'] * 100) / 100).toFixed(2);
      this.PO['iva'] = (Math.round(this.PO['iva'] * 100) / 100).toFixed(2);
      var obj = {
        ObjectId:Id.objectId,
        type:'GetDetails'
      }
      this.pservice.PortalQueries(obj).subscribe(data => {
        console.log(data);
        console.log(this.PO);
        this.ListaDetalles = data[0]
        console.log(this.ListaDetalles);
        this.actual = "detalles"
        this.spinnerService.hide();

      },
        error => {
          this.handleError(error);
        });
    }

    CargarPedidos(type : string = 'normal') {
      this.Data = {type:"default", POHdrObjectId:0, ProvidersList:[], PODate:'', ediShipToReferenceTypeObjectId:0, Vendor:'', SelectedECPM:'', customerPartyMasterObjectId:0, 
      RGList:[], PPList:[], CHCList:[], nListaCPM:[], ListaCPMS:[], BranchParty:"", BranchPartyEAN:"", PONumber:"", CPMCode:""}
      this.spinnerService.show();

      var obj = {
        PODate1:this.PODate1,
        PODate2:this.PODate2,
        PONumber:this.PONumber,
        Usuario:this.Auth.objectId,
        OrderType:this.OrderType
      }
      console.log(obj);
      this.actual = ''
      this.pservice.GetPedidos(obj).subscribe(data => {
        console.log(data)
        this.ListaPedidos = data[0]
        this.ListaOrderTypes = data[1]
        console.log(this.ListaPedidos);
        this.ListaPedidos.forEach(e => {
            var arreglo = e.poDate.split("T")
            e.poDate = arreglo[0];
            arreglo = e.dateCreated.split("T");
            e.dateCreated = arreglo[0];
        });
        this.spinnerService.hide()
        if(type == 'Filter'){
          this.toastr.Success("Filtro aplicado con éxito")
        }
        

      },
        error => {
          this.handleError(error);
        });
    }
    
    private handleError(error: any) {

      this.spinnerService.hide();
      if (error.status == 401) {
        localStorage.removeItem('auth');
        this.router.navigate(['login'])
      }
      this.toastr.Error("Error al cargar la información");
  
    };

    eliminar(e:any) {
      this.spinnerService.show();

      var obj = {
        ObjectId:e.objectId,
        type:'Cancel',
        String1:this.Auth.objectId
      }
      this.pservice.PortalQueries(obj).subscribe(data => {
        this.spinnerService.hide()
        this.toastr.Success("Pedido cancelado con éxito")
        this.CargarPedidos();
      },
        error => {
          this.handleError(error);
        });

    }
    confirmar(e:any) {

      this.spinnerService.show();

      var obj = {
        ObjectId:e.objectId,
        type:'Save',
        String1:this.Auth.objectId
      }
      this.pservice.PortalQueries(obj).subscribe(data => {

        this.spinnerService.hide()
        if(data[0].error){
          this.toastr.Error(data[0].error)
          return
        }
        this.toastr.Success("Pedido confirmado con éxito")
        this.CargarPedidos();
      },
        error => {
          this.handleError(error);
        });

    }

    detalles(row:any) {

      //this.actual = "detalles"
    }
   
    agregar(e:any) {
      this.PO = e
      this.PO['total'] = parseFloat(this.PO.subtotal) + parseFloat(this.PO.ieps) + parseFloat(this.PO.iva)
      console.log(this.PO['total']);
      this.PO['total'] = (Math.round(this.PO['total'] * 100) / 100).toFixed(2);
      this.PO['subtotal'] = (Math.round(this.PO['subtotal'] * 100) / 100).toFixed(2);
      this.PO['ieps'] = (Math.round(this.PO['ieps'] * 100) / 100).toFixed(2);
      this.PO['iva'] = (Math.round(this.PO['iva'] * 100) / 100).toFixed(2);
      var obj = {
        POHdrObjectId:e.objectId,
        Code:'',
        Nombre:'',
        EAN13:'',
        DUN14:'',
        DUN14T:''
      }
      this.spinnerService.show();
      this.pservice.PPConsultaRecursosFiltros(obj).subscribe(data => {
        this.DataRecursos = data;
        this.DataRecursos["POHdrObjectId"] = e.objectId
        this.spinnerService.hide();
        this.actual = 'principal'
      },
        error => {
          this.handleError(error);
        });
      
    }

    onVoted(e){
      this.actual = "";
    }

    editar(e){
      console.log(e)
      this.spinnerService.show();
      var obj = {
        ECPMObjectId:e.ediCustomerPartyMasterObjectId,
        RGObjectId:0,
        PPObjectId:0,
        CCObjectId:0,
        Codigo:'',
        Tienda:'',
        TiendaST3:'',
        Proveedor:'',
        type:'GetCPMByEDIShipToReferenceType',
        CPMObjectId:e.customerPartyMasterObjectId
      }
  
      this.pservice.PP_NuevoPedido_Queries(obj).subscribe(data => {
        console.log(data)
        this.Data.RGList = data[0]
        this.Data.PPList = data[1]
        this.Data.CHCList = data[2]
        this.Data.CPMCode = data[4][0]["code"]
        if(data[3][0]["tienda ST3"] == null){
          this.Data.nListaCPM = data[3]
          this.Data.nListaCPM.forEach(e => {
            var x = e.branchPartyName.trim()
            if(x.length > 0){
                e.code = e.code.trim();
                e.description = e.description.trim();
                e.branchPartyName = e.branchPartyName.trim();
            }
          });

        }else{
          this.Data.ListaCPMS = data[3]
          this.Data.ListaCPMS.forEach(e => {
            var x = e.branchPartyName.trim()
            if(x.length > 0){
                e.description = e.branchPartyName.trim();
            }
          });
        }
        if(e.ecpms > 0)
        {
          this.Data.ProvidersList = []
          var x = {createdByUserObjectId: 0,
            dateCreated: "2020-04-13T13:17:44.277",
            dateModified: null,
            description: "Proveedor de EDIInfHeader",
            modifiedByUserObjectId: null,
            noDeProveedor: e.vendorParty,
            objectId: 9999,
            parentClassId: 27,
            parentObjectId: 17,
            statusObjectId: 1
          }
          this.Data.ProvidersList.push(x)
        }else{
          this.Data.ProvidersList = data[5]
        }
        this.Data.SelectedECPM = e.ediCustomerPartyMasterObjectId;
        this.Data.BranchParty= e.branchPartyNumber;
        this.Data.BranchPartyEAN = e.branchPartyEAN;
        this.Data.PONumber = e.poNumber
        this.Data.type = 'editPO'
        this.Data.PONumber = e.poNumber;
        this.Data.PODate = e.poDate;
        this.Data.Vendor = e.ecpmp;
        this.Data.ediShipToReferenceTypeObjectId = e.ediShipToReferenceTypeObjectId;
        this.Data.customerPartyMasterObjectId = e.customerPartyMasterObjectId;
        this.spinnerService.hide()
        this.actual = 'editPO'
        this.Data.POHdrObjectId = e.objectId

      
      },
        error => {
          this.handleError(error);
        });
    }

    showpadre(e){

      if(e.length > 0){
        this.ListaPedidos = e
        this.ListaPedidos.forEach(ex => {
            var arreglo = ex.poDate.split("T")
            ex.poDate = arreglo[0];
            arreglo = ex.dateCreated.split("T");
            ex.dateCreated = arreglo[0];
        });
        console.log(this.ListaPedidos);
        var x = {}
        for (let i = 0; i < this.ListaPedidos.length; i++) {
          if(this.ListaPedidos[i].objectId == this.ListaPedidos["POHdrObjectId"]){
              x = this.ListaPedidos[i];
              break;
          }
          
        }

        this.CargarRecursos(x)
      }else{
        this.CargarPedidos()
      }
    }

    Filter(){
      this.CargarPedidos('Filter')
    }

    

    volver(){
      if(this.actual == 'b'){
        
      }else if(this.actual == "detalles"){
        this.actual = ""
      }
    }

    

  }
