import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { SharedModule } from "../../../../shared/shared.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ToastyModule } from "ng2-toasty";

import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { AuthGuardService } from "../../../../shared/auth-guard/auth-guard.service";

import { NgSelectModule } from "@ng-select/ng-select";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KeysPipe } from "src/app/pipes/keypipe/keys.pipe";
import { SanitizeHtmlPipe } from "src/app/pipes/htmlsafe/htmlsafe.pipe";
//import { PrincipalComponent } from "../../pagos/principal/principal.component";
/* import { DocumentossaldosComponent } from '../../pagos/documentossaldos/documentossaldos.component'; */

//import { PodComponent } from "../../pagos/pod/pod/pod.component";
import { NgxPaginationModule } from "ngx-pagination";
/* import { DetalleComponent } from '../../pagos/detalle/detalle.component';
import { EditarComponent } from '../../pagos/editar/editar.component'; */
import { TimbradoComponent } from "../../timbrado/timbrado/timbrado.component";
/* import { CcprincipalComponent } from '../../pagos/ccprincipal/ccprincipal.component'; */
/* import { ApplicationsettingsComponent } from '../../seguridad/applicationsettings/applicationsettings.component'
import { ApplicationsettingsbyprofileComponent } from '../../seguridad/applicationsettingsbyprofile/applicationsettingsbyprofile.component'
import { EdiggroupsecurityComponent } from '../../seguridad/ediggroupsecurity/ediggroupsecurity.component'
import { EdiguserComponent } from '../../seguridad/ediguser/ediguser.component'
import { EdigusergrouprelationComponent } from '../../seguridad/edigusergrouprelation/edigusergrouprelation.component' */
import { SeguridadComponent } from '../../timbrado/seguridad/seguridad.component'
/* import { RecuperarxmlComponent } from '../../comprobantes/companias/recuperarxml/recuperarxml.component' */

import { ModalComponent } from '../../timbrado/modal/modal.component'
import { ModalService } from '../../timbrado/modal/modal.service'
import { ComprobantesModule } from "../../comprobantes/module/comprobantes.module"; 


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgxDatatableModule,
    ToastyModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    NgxPaginationModule,
    NgbModule,
    ComprobantesModule
  ],
  declarations: [
    //PrincipalComponent,
    //PodComponent,
    /* DetalleComponent,
    EditarComponent, */
    TimbradoComponent,
/*     CcprincipalComponent,
    DocumentossaldosComponent,
    ApplicationsettingsComponent,
    ApplicationsettingsbyprofileComponent,
    EdiggroupsecurityComponent,
    EdiguserComponent,
    EdigusergrouprelationComponent, */
    SeguridadComponent,
    ModalComponent
    //   SanitizeHtmlPipe
  ],
  exports: [TimbradoComponent],
  providers: [AuthGuardService, ModalService],
})
export class TimbradoModule { }
