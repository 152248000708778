import { Component, OnInit } from '@angular/core';
import { SeguridadService } from "src/app/services/bi/seguridad/seguridad.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-ediggroupsecurity',
  templateUrl: './ediggroupsecurity.component.html',
  styleUrls: ['./ediggroupsecurity.component.scss',
    "../../../../../assets/icon/icofont/css/icofont.scss"],
  providers: [DatePipe]
})
export class EdiggroupsecurityComponent implements OnInit {
  listaEdigGroups: any = [];
  listaBusquedaGrid: any =[]
  buscar: any = ""
  editar: any = 0;
  agregar: any = 0;
  itemSeleccionado: boolean = true;
  rowSeleccionado: any = {}
  nombreGrupo: any = "";
  constructor(private seguridadService: SeguridadService,
    private spinnerService: Ng4LoadingSpinnerService,
    private datePipe: DatePipe) {
    this.cargaInicial();
  }

  ngOnInit() {
  }

  cargaInicial() {
    this.spinnerService.show();
    this.seguridadService.ObtenerGroupsLista({}).subscribe(data => {
      this.spinnerService.hide();
      this.listaEdigGroups = data;
      this.listaBusquedaGrid = data;
      console.log(data)
      // console.log(data);
    },
      error => {
        console.log(error)
      })
  }

  reaload() {
    this.cargaInicial();
  }

  detalle(row) {
    this.itemSeleccionado = false;
    this.rowSeleccionado = row;
    console.log(row)
  }

  delete() {
    var body = this.rowSeleccionado

    // console.log(body)
    this.spinnerService.show()
    this.seguridadService.DeleteGrupo(body).subscribe(data => {
      this.spinnerService.hide()
      this.cargaInicial();
      console.log(data)
    },
      error => {
        console.log(error)
        this.spinnerService.hide()
      })
    // console.log(body)
  }

  guardarNewGroup() {
    this.agregar = 0;

    var body = {
      Name: this.nombreGrupo,
      DateCreated: this.datePipe.transform(Date.now(), 'yyyy-MM-dd')
    }
    this.nombreGrupo = ""

    console.log(body)
    this.spinnerService.show()
    this.seguridadService.GuardarNuevoGrupo(body).subscribe(data => {
      this.spinnerService.hide();
      this.cargaInicial();
      console.log(data);
      // this.agregar = 0;

    },
      error => {
        console.log(error)
        this.spinnerService.hide()
      })
  }
  guardarCambiosGrupo() {
    var body = this.rowSeleccionado;

    this.seguridadService.GuardarCambiosGrupo(body).subscribe(data => {
      this.spinnerService.hide();
      this.editar = 0;
      this.cargaInicial();
      console.log(data)
    },
      error => {
        console.log(error)
        this.spinnerService.hide();
      })
  }
  Buscar(){
    
    this.listaBusquedaGrid = []
    for (let i = 0; i < this.listaEdigGroups.length; i++) {
      
      for (let x = 0; x < Object.keys(this.listaEdigGroups[0]).length; x++) {
        var propiedades = Object.keys(this.listaEdigGroups[i])
        var propiedad = propiedades[x]
        var valor = String(this.listaEdigGroups[i][propiedad]).toLocaleUpperCase()
       
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGrid.push(this.listaEdigGroups[i])
          break
        }
          
      }
    }

  }

}
