import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';
@Injectable({
  providedIn: 'root'
})
export class RfcsServiceService {

  private httpOptions2 = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  constructor(private http: HttpClient) { }

  private httpOptions: any;

  //  public get() {
  //    return this.http.get(`${environment.apiUrl}/transactions/rfcs`)
  //    .map(res => res);
  //  }


  public get() {
    this.httpOptions = this.getToken();
    return this.http.get(`${environment.apiUrl}/transactions/RFCs`, { ...this.httpOptions })
      .map(res => res);
  }



  getToken() {
    let httpOptions2 = {};

    var data = localStorage.getItem('auth');
    let auth = JSON.parse(data);

    return httpOptions2 = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      })
    };
  }



}
