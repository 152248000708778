import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  login(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    let json = JSON.stringify(user);
    return this.http.post(`${environment.apiUrlSecurity}/sesion`, json, httpOptions)
  }

  Savesresponse(responce) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };


    return this.http.post(`${environment.apiUrlSecurity}/sesion/Savesresponse`, responce, httpOptions)

  }

  public setSession(data) {
    localStorage.setItem('auth', JSON.stringify(data));
    this.router.navigate(['default']);
  }

  private handleError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
  };

  logout() {

    window["signOutGoogle"]().then(() => {
      // Sign-out successful.
      localStorage.removeItem('auth');
      this.router.navigate(['login'])
    }).catch((error) => {
      // An error happened.
    });

  }

  public isLoggedIn() {
     let flag = !!localStorage.getItem('auth');
     console.log(flag);
     return flag;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getToken() {
    return localStorage.getItem('auth');
  }

}

