import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { PagosService } from 'src/app/services/bi/pagos/pagos.service';
import { AuthService } from 'src/app/services/self/seguridad/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-ccprincipal',
  templateUrl: './ccprincipal.component.html',
  styleUrls: ['./ccprincipal.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss'],
})
export class CcprincipalComponent implements OnInit {

  @Output() padre = new EventEmitter();
  @Input() selectedDS : any = "";
  @Input() hdrCartera : any = [];
  @Input() hdrDetail : any = [];
  @Input() filtrosP : any = [];
  @Input() titulo : any = "";
  valorECPM:any = false;
  selectedDocumento: any = {}
  documento : any = ""

  //General
  visibleCards: any = [true,true,true,true]
  Auth: any = {};
  actual:any = ""
  timefired : any  = null;

  //Card 0

  //card 1

  //card 2
  filtros = {
    listaECPM  : [],
    selectedECPM  : 0,
    listaCPM  : [],
    selectedCPM  : 0, //167405
    listaRG  : [],
    selectedRG  : 0,
    listaPP  : [],
    xlistaPP  : [],
    selectedPP  : 0,
    listaDT  : [],
    selectedDT  : 0,
    listaDST  : [],
    selectedDST  : 0,
    listaMA  : [],
    selectedMA  : 0,
    PDate1 : "",
    PDate2 : "",
  }

  filtrosAplicados = {
    selectedECPM  : 0,
    selectedCPM  : 0, //167405
    selectedRG  : 0,
    selectedPP  : 0,
    selectedDT  : 0,
    selectedDST  : 0,
    selectedMA  : 0,

    listaECPM:[],
    listaRG : [],
    listaPP:[],
    xlistaPP:[],
    documento : "",
    PDate1 : "",
    PDate2 : "",
  }


  //card 3
  listaGridPrincipal : any = []
  listaBusquedaGridPrincipal : any = []
  buscar: any= ""
  
  
  constructor(private toastr:CommontoastrService, private pservice:PagosService, private spinnerService: Ng4LoadingSpinnerService) { 
    var data = localStorage.getItem('auth');
    if (data != undefined && data != null) {
      this.Auth = JSON.parse(data);
    }
    
  }

  ngOnInit() {
    if(this.titulo.length > 0){
      this.titulo += " - Conciliación de cartera"
    }else{
      this.titulo = "Conciliación de cartera"
    }
    if(this.filtrosP.length > 0){
      this.filtros.selectedECPM = this.filtrosP.selectedECPM
      this.filtros.listaPP = this.filtrosP.listaPP
      this.filtros.xlistaPP = this.filtrosP.xlistaPP
      this.filtros.listaRG = this.filtrosP.listaRG
      this.filtros.selectedPP = this.filtrosP.selectedPP
      this.filtros.selectedRG = this.filtrosP.selectedRG
      this.filtros.selectedDST = this.filtrosP.selectedDST
      this.filtros.selectedDT = this.filtrosP.selectedDT
    }
    this.cargaInicial()
  }

   /////////////////---------------Metodos--------------/////////////////////
  //General
  toggleCard(e){
    this.visibleCards[e] = !this.visibleCards[e]
  }

  cargaInicial(){
    this.documento = this.selectedDS
    this.spinnerService.show()
    this.pservice.ObtenerCombos({}).subscribe(data => {
      this.filtros.listaECPM = data['ecpm']
      this.filtros.listaDT = data['dt']
      this.filtros.listaDST = data['dSt']
      this.filtros.listaMA = data['ma']
      if(this.selectedDS == ""){
        this.spinnerService.hide()
      }else{
        this.filtrar()
      }

    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });
  }

  test(e:any){
    clearTimeout(this.timefired);
    var x = this.spinnerService;
    var y = this.pservice;
    var z = this
    if(this.filtros.selectedECPM == NaN || this.filtros.selectedECPM == null)
      this.filtros.selectedECPM = 0
    var obj = {
      
      EDI:this.filtros.selectedECPM,
      RG:this.filtros.selectedRG,
      PP:this.filtros.selectedPP,
      texto:e.term
    }
    this.timefired = setTimeout(function () {
      
      console.log(obj);
      x.show()
      y.ObtenerClienteCPM(obj).subscribe(data => {
        x.hide()
        console.log(data);
        // @ts-ignore
        z.listaCPM=data
        
       
        
        
      },
        error => {
          x.hide()
          console.log("Error");
          console.log(error);
          
          //this.handleError(error);
        });
    }, 2000);
      
  
  }

  selectECPM(){
    this.spinnerService.show()
    
    this.pservice.ObtenerCombosRGyPP(this.filtros.selectedECPM).subscribe(data => {
      this.spinnerService.hide()
      this.filtros.listaPP = data['pp']
      this.filtros.xlistaPP = this.filtros.listaPP
      this.filtros.listaRG = data['rg']
  
      
      
      
    },
    error => {
      this.spinnerService.hide()
      console.log("Error");
      console.log(error);
      //this.handleError(error);
    });
  }

  changeRG(){
    if(this.filtros.selectedRG == 0){
      this.filtros.xlistaPP = this.filtros.listaPP
      this.filtros.selectedPP = 0;
      return
    }
    this.filtros.xlistaPP = []
    this.filtros.listaPP.forEach(e => {
      if(e.referenceGroupObjectId == this.filtros.selectedRG)
      this.filtros.xlistaPP.push(e)
    });

    if(this.filtros.xlistaPP[this.filtros.xlistaPP.findIndex(x => x.objectId == this.filtros.selectedPP)] == undefined){
      this.filtros.selectedPP = 0;
    }


  }

  changePP(){
    if(this.filtros.selectedPP == 0)
      return
    var PP = {}
    for (let i = 0; i < this.filtros.listaPP.length; i++) {
      if(this.filtros.listaPP[i].objectId == this.filtros.selectedPP)
        PP = this.filtros.listaPP[i]  
    }

    this.filtros.selectedRG = PP['referenceGroupObjectId']
    this.changeRG()
  }

  filtrar(){
    if(this.filtros.selectedECPM == NaN || this.filtros.selectedECPM == null)
      this.filtros.selectedECPM = 0
      this.filtrosAplicados.selectedECPM = this.filtros.selectedECPM;
    

    this.filtrosAplicados.listaECPM.push(this.filtros.listaECPM[this.filtros.listaECPM.findIndex(x => x.objectId == this.filtros.selectedECPM)])
    this.filtrosAplicados.listaPP = this.filtros.listaPP
    this.filtrosAplicados.xlistaPP = this.filtros.xlistaPP
    this.filtrosAplicados.listaRG = this.filtros.listaRG
    this.filtrosAplicados.selectedCPM = this.filtros.selectedCPM;
    this.filtrosAplicados.selectedRG = this.filtros.selectedRG;
     // @ts-ignore
    this.filtrosAplicados.selectedPP = parseInt(this.filtros.selectedPP);
    this.filtrosAplicados.selectedDT = this.filtros.selectedDT;
    this.filtrosAplicados.selectedDST = this.filtros.selectedDST;
    this.filtrosAplicados.PDate1 = this.filtros.PDate1==""?'2000-01-01':this.filtros.PDate1
    this.filtrosAplicados.PDate2 = this.filtros.PDate2==""?'2022-01-01':this.filtros.PDate2
    // @ts-ignore
    this.filtrosAplicados.Monto1 = this.filtros.Monto1==""?0:parseFloat(this.filtros.Monto1)
    // @ts-ignore
    this.filtrosAplicados.Monto2 = this.filtros.Monto2==""?0:parseFloat(this.filtros.Monto2)
    
    var body = {
      EDI:this.filtros.selectedECPM,
      CPM:this.filtros.selectedCPM,
      RG:this.filtros.selectedRG,
      PP:this.filtros.selectedPP,
      DT:this.filtros.selectedDT,
      DSt:this.filtros.selectedDST,
      MA:this.filtros.selectedMA,
      documento:this.documento
    }

    console.log(body);
    this.spinnerService.show()
    
    this.pservice.ObtenerListaDocumentosPrincipal(body).subscribe(data => {
      console.log(data);
      this.spinnerService.hide()
      this.listaGridPrincipal = data[0]
      this.listaBusquedaGridPrincipal = data[0]
      
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });
  }

  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  exportar(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.listaGridPrincipal);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    XLSX.writeFile(workbook, this.toExportFileName("data"));
  }

  volver(){
    this.padre.emit([]);
  }
  Buscar(){
    
    this.listaBusquedaGridPrincipal = []
    for (let i = 0; i < this.listaGridPrincipal.length; i++) {
      
      for (let x = 0; x < Object.keys(this.listaGridPrincipal[0]).length; x++) {
        var propiedades = Object.keys(this.listaGridPrincipal[i])
        var propiedad = propiedades[x]
        var valor = String(this.listaGridPrincipal[i][propiedad]).toLocaleUpperCase()
       
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGridPrincipal.push(this.listaGridPrincipal[i])
          break
        }
          
      }
    }

  }

}
