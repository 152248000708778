import { Component, OnInit } from '@angular/core';
import { PagosService } from 'src/app/services/bi/pagos/pagos.service';
import { AuthService } from 'src/app/services/self/seguridad/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';

@Component({
  selector: 'app-documentossaldos',
  templateUrl: './documentossaldos.component.html',
  styleUrls: ['./documentossaldos.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss'],
})
export class DocumentossaldosComponent implements OnInit {

  selectedPago : any = {}
  selectedDS: any = {}
  hdrCartera : any = []

  //General
  titulo:any = "Cartera"
  Auth: any = {};
  actual:any = ""
  timefired : any  = null;
  visibleCards: any = [true,true,false,true]

  //Card 0
  listaGridPrincipal = []
  listaBusquedaGridPrincipal = []
  filtros = { 
    listaECPM : [],
    selectedECPM : 0,//17
    listaCPM : [],
    selectedCPM : 0,
    listaRG : [],
    selectedRG : 0,
    listaPP : [],
    xlistaPP : [],
    selectedPP : 0,
    documento : "",
    listaDT : [],
    selectedDT : 0,//2
    listaDST : [],
    selectedDST : 0,
    PDate1 : "",
    PDate2 : "",
    Monto1 : "",
    Monto2 : "",
  }
  filtrosAplicados = {
    selectedECPM : 0,
    listaECPM:[],
    selectedCPM : 0,
    selectedRG : 0,
    listaRG : [],
    selectedPP : 0,
    listaPP:[],
    xlistaPP:[],
    documento : "",
    selectedDT : 0,
    selectedDST : 0,
    PDate1 : "",
    PDate2 : "",
    Monto1 : "",
    Monto2 : "",
  } 
  
  buscar : any = ""

  

  
  constructor(private toastr:CommontoastrService, private pservice:PagosService, private spinnerService: Ng4LoadingSpinnerService) { 
    //Obtener usuario firmado
    var data = localStorage.getItem('auth');
    if (data != undefined && data != null) {
      this.Auth = JSON.parse(data);
    }

    //this.cargaInicial()
  }

  ngOnInit() {
    //this.filtrar()
    this.cargaCombos()
  }

  toggleCard(e){
    this.visibleCards[e] = !this.visibleCards[e]
  }

  cargaCombos(){
    this.spinnerService.show()
    this.pservice.ObtenerCombos({}).subscribe(data => {
      this.filtros.listaECPM = data['ecpm']
      this.filtros.listaDT = data['dt']
      this.filtros.listaDST = data['dSt']
      this.spinnerService.hide()
      //this.filtrar()
    },
      error => {
        console.log("Error");
        console.log(error);
        this.spinnerService.hide()
        this.toastr.Error("Error al cargar los combos de información")
      });
  }

  cargaInicial(){
    this.spinnerService.show()
    this.pservice.ObtenerCombos({}).subscribe(data => {
      this.filtros.listaECPM = data['ecpm']
      this.filtros.listaDT = data['dt']
      this.filtros.listaDST = data['dSt']
      this.spinnerService.hide()

  

    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });
  }

  test(e:any){
    clearTimeout(this.timefired);
    var x = this.spinnerService;
    var y = this.pservice;
    var z = this
    if(this.filtros.selectedECPM == NaN || this.filtros.selectedECPM == null)
      this.filtros.selectedECPM = 0
    var obj = {
      
      EDI:this.filtros.selectedECPM,
      RG:this.filtros.selectedRG,
      PP:this.filtros.selectedPP,
      texto:e.term
    }
    this.timefired = setTimeout(function () {
      
      
      x.show()
      y.ObtenerClienteCPM(obj).subscribe(data => {
        x.hide()
        console.log(data);
        // @ts-ignore
        z.filtros.listaCPM=data
        
       
        
        
      },
        error => {
          x.hide()
          console.log(error);
          console.log("Error");
          
          //this.handleError(error);
        });
    }, 2000);
      
  
  }

  selectECPM(){
    if(this.filtros.selectedECPM == null){
      this.filtros.selectedECPM = 0;
      this.filtros.selectedPP = 0
      this.filtros.listaPP = []
      this.filtros.xlistaPP = []
      this.filtros.selectedRG = 0
      this.filtros.listaRG = []
      return
    }
    this.spinnerService.show()
    
    this.pservice.ObtenerCombosRGyPP(this.filtros.selectedECPM).subscribe(data => {
      this.filtros.listaPP = data['pp']
      this.filtros.xlistaPP = this.filtros.listaPP
      this.filtros.listaRG = data['rg']
  
      this.spinnerService.hide()
      
      
      
    },
    error => {
      this.spinnerService.hide()
      console.log(error);
      console.log("Error");
      //this.handleError(error);
    });
  }

  changeRG(){
    if(this.filtros.selectedRG == 0){
      this.filtros.xlistaPP = this.filtros.listaPP
      this.filtros.selectedPP = 0;
      return
    }
    this.filtros.xlistaPP = []
    this.filtros.listaPP.forEach(e => {
      if(e.referenceGroupObjectId == this.filtros.selectedRG)
      this.filtros.xlistaPP.push(e)
    });

    if(this.filtros.xlistaPP[this.filtros.xlistaPP.findIndex(x => x.objectId == this.filtros.selectedPP)] == undefined){
      this.filtros.selectedPP = 0;
    }


  }

  changePP(){
    if(this.filtros.selectedPP == 0)
      return
    var PP = {}
    for (let i = 0; i < this.filtros.listaPP.length; i++) {
      if(this.filtros.listaPP[i].objectId == this.filtros.selectedPP)
        PP = this.filtros.listaPP[i]  
    }

    this.filtros.selectedRG = PP['referenceGroupObjectId']
    this.changeRG()
  }

  filtrar(){
    if(this.filtros.selectedECPM == NaN || this.filtros.selectedECPM == null)
      this.filtros.selectedECPM = 0

    this.filtrosAplicados.selectedECPM = this.filtros.selectedECPM;

    this.filtrosAplicados.listaECPM.push(this.filtros.listaECPM[this.filtros.listaECPM.findIndex(x => x.objectId == this.filtros.selectedECPM)])
    this.filtrosAplicados.listaPP = this.filtros.listaPP
    this.filtrosAplicados.xlistaPP = this.filtros.xlistaPP
    this.filtrosAplicados.listaRG = this.filtros.listaRG
    this.filtrosAplicados.selectedCPM = this.filtros.selectedCPM;
    this.filtrosAplicados.selectedRG = this.filtros.selectedRG;
     // @ts-ignore
    this.filtrosAplicados.selectedPP = parseInt(this.filtros.selectedPP);
    this.filtrosAplicados.selectedDT = this.filtros.selectedDT;
    this.filtrosAplicados.selectedDST = this.filtros.selectedDST;
    this.filtrosAplicados.PDate1 = this.filtros.PDate1==""?'2000-01-01':this.filtros.PDate1
    this.filtrosAplicados.PDate2 = this.filtros.PDate2==""?'2022-01-01':this.filtros.PDate2
    // @ts-ignore
    this.filtrosAplicados.Monto1 = this.filtros.Monto1==""?0:parseFloat(this.filtros.Monto1)
    // @ts-ignore
    this.filtrosAplicados.Monto2 = this.filtros.Monto2==""?0:parseFloat(this.filtros.Monto2)
    
    var body = {
      ECPMObjectId:this.filtros.selectedECPM,
      CPMObjectId:this.filtros.selectedCPM,
      RGObjectId:this.filtros.selectedRG,
      PPObjectId:this.filtros.selectedPP,
      DTObjectId:this.filtros.selectedDT,
      DSTObjectId:this.filtros.selectedDST,
      DateStart:this.filtros.PDate1==""?'2000-01-01':this.filtros.PDate1,
      DateEnd:this.filtros.PDate2==""?'2022-01-01':this.filtros.PDate2,
      AmountBalanceStart:this.filtros.Monto1==""?0:parseFloat(this.filtros.Monto1),
      AmountBalanceEnd:this.filtros.Monto2==""?999999:parseFloat(this.filtros.Monto2),
      Documento:this.filtros.documento
    }

    this.spinnerService.show()
    console.log(body);
    this.pservice.ConsultaDocumentoSaldadoPrincipal(body).subscribe(data => {
      console.log(data);
      this.listaGridPrincipal = data[0]
      this.listaBusquedaGridPrincipal = data[0]
      this.spinnerService.hide()
      
    },
      error => {
        this.spinnerService.hide()
        console.log("Error");
        console.log(error);
        //this.handleError(error);
      });
  }


  getccartera(e){
    this.selectedDS = e['Folio del Documento']
    this.hdrCartera = []
    this.hdrCartera.push(e)
    this.actual = "ccartera"
  }

  getdetalle(e){
    this.selectedPago = e
    this.actual = "detalle"
  }

  cabecero(e){
    this.selectedDS = e['Id']
    this.spinnerService.show()
    var obj = {
      UserId:this.Auth.objectId,
      IdDocument:e.Id
    }
    
    this.pservice.DSMostrarPrincipal(obj).subscribe(data => {
      this.spinnerService.hide()
      this.actual = "principal"
  
      
      
      
    },
    error => {
      this.spinnerService.hide()
      console.log("Error");
      console.log(error);
      //this.handleError(error);
    });
  }

  showpadre(e:any){
    this.actual = ""
    this.filtrar()
  }

  Buscar(){
    
    this.listaBusquedaGridPrincipal = []
    for (let i = 0; i < this.listaGridPrincipal.length; i++) {
      
      for (let x = 0; x < Object.keys(this.listaGridPrincipal[0]).length; x++) {
        var propiedades = Object.keys(this.listaGridPrincipal[i])
        var propiedad = propiedades[x]
        var valor = String(this.listaGridPrincipal[i][propiedad]).toLocaleUpperCase()
       
        if(valor.indexOf(this.buscar.toLocaleUpperCase()) != -1){
          this.listaBusquedaGridPrincipal.push(this.listaGridPrincipal[i])
          break
        }
          
      }
    }

  }
}
