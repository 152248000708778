import { AuthService } from './../../../../services/self/seguridad/auth.service';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ScreenActionsService } from 'src/app/services/self/seguridad/actions.service';
import { NotificacionService } from 'src/app/services/bi/operacion/notificaciones.service';
import { ConciliacionService } from 'src/app/services/bi/conciliaciones/conciliacion.service';
import { ExcelService } from 'src/app/services/bi/operacion/excel.service';

@Component({
  selector: 'app-sharecomponent',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss'],
})
export class ShareComponent implements OnInit {
  @Input() DataInput: any = [];
  @Input() dataShared: boolean = false;
  //--Acciones y Permisos ---
  public Page: any = {};
  public Screen: any = {};
  //Manejo de Pantalla

  ListaMonedas: any = [];
  ListaDocumentos: any = [];
  Filter: any = {};
  RfcSeleccionado: any = {};
  //Otros 
  Auth: any = {};
  Task: any = {};
  //Facturacion
  ListaFacturacion: any = [];
  ListaDiferenciaFolios: any = [];
  public p: number;
  Periodo: any = {};
  //Paginacion
  itemsPerPage: any;
  ListaItemsPerPage: any = [];

  constructor(private router: Router, private toastr: CommontoastrService, private AuthService: AuthService,
    private spinnerService: Ng4LoadingSpinnerService, private ScreenActionsService: ScreenActionsService,
    private NotificacionService: NotificacionService, private ConciliacionService: ConciliacionService,
    private ExcelService: ExcelService) {
    this.Page.AllowView = false;
    this.Page.AllowAdd = false;
    this.Page.AllowEdit = false;
    this.Page.Controller = this.router.routerState.snapshot.url.toString().substr(this.router.routerState.snapshot.url.lastIndexOf('/') + 1, this.router.routerState.snapshot.url.toString().length - 1);
    this.Filter.ObjectId = "0";
    //this.AuthService.IsAlive();
    this.itemsPerPage = 10;
    this.ListaItemsPerPage.push({ Id: 10, value: "10 registros" }, { Id: 20, value: "20 registros" }, { Id: 50, value: "50 registros" })
    this.ListaMonedas.push({ Id: '1', value: "Todas" }, { Id: 'MXN', value: "MXN" }, { Id: 'USD', value: "USD" }, { Id: 'EUR', value: "EUR" })

    this.Filter.Moneda = "1";


  }

  ngOnInit() {

    console.log(this.DataInput);
  }

  public Verifica() {
    console.log(this.DataInput);
  }

  onChangePlant(id) {
   this.ConciliacionService.FromShareService = this.Filter.ObjectId;
   console.log(this.Filter.ObjectId);
  }

  


  //---------Funciones Manejo de Errores--------------------------
  private handleError(error: any) {

    this.spinnerService.hide();
    if (error.status == 401) {
      localStorage.removeItem('auth');
      this.router.navigate(['login'])
    }
    this.toastr.Error(error.error.Descripcion);

  };


}
